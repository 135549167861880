import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MdFormatBold as BoldIcon,
  MdFormatItalic as ItalicIcon,
  MdOutlineStrikethroughS as StrikeThroughIcon
} from 'react-icons/md';
import { Badge, Tooltip } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type KnackObject } from '@/types/schema/KnackObject';
import {
  type DetailsViewDisplayRule,
  type DetailsViewDisplayRuleAction
} from '@/types/schema/views/DetailsView';
import { InlineKnackCriteriaValue } from '@/components/InlineKnackCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackValue } from '@/components/InlineKnackValue';
import { EmptyRuleValue } from '@/pages/pages/settings-panel/view-settings/common/EmptyRuleValue';
import { SortableCardWrapper } from '@/pages/pages/settings-panel/view-settings/common/SortableCardWrapper';
import { DetailsDisplayRuleFormDialog } from '@/pages/pages/settings-panel/view-settings/details/display-rules/DetailsDisplayRuleFormDialog';

interface DetailsDisplayRuleCardProps {
  displayRule: DetailsViewDisplayRule;
  sourceObject: KnackObject;
  ruleNumber: number;
  isInvalid?: boolean;
  shouldScrollIntoView?: boolean;
  isDragOverlay?: boolean;
  onRuleSave: (updatedDisplayRule: DetailsViewDisplayRule) => void;
  onRuleDuplicate: (displayRuleToDuplicate: DetailsViewDisplayRule) => void;
  onRuleDelete: (displayRuleKey: DetailsViewDisplayRule['key']) => void;
  dataTestId?: string;
}

export function DetailsDisplayRuleCard({
  displayRule,
  sourceObject,
  ruleNumber,
  isInvalid = false,
  shouldScrollIntoView = false,
  isDragOverlay = false,
  onRuleSave,
  onRuleDuplicate,
  onRuleDelete,
  dataTestId
}: DetailsDisplayRuleCardProps) {
  const [t] = useTranslation();
  const availableViewFields = sourceObject.fields;

  const displayRuleCardRef = useRef<HTMLDivElement | null>(null);
  const [isEditRuleModalOpen, setIsEditRuleModalOpen] = useState(false);

  const STATIC_TEXTS = {
    'text-style': t('components.display_rule_card.actions.text_style'),
    'bg-color': t('components.display_rule_card.actions.bg_color'),
    'text-color': t('components.display_rule_card.actions.text_color'),
    show: t('components.display_rule_card.actions.show'),
    hide: t('components.display_rule_card.actions.hide'),
    label: t('components.display_rule_card.actions.label')
  };

  useEffect(() => {
    if (shouldScrollIntoView && displayRuleCardRef.current) {
      displayRuleCardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTextStyleIcon = (action: DetailsViewDisplayRuleAction) => (
    <div className="flex items-center gap-1">
      {action.bold && (
        <div className="rounded-lg border border-emphasis p-1">
          <BoldIcon size={16} />
        </div>
      )}
      {action.italic && (
        <div className="rounded-lg border border-emphasis p-1">
          <ItalicIcon size={16} />
        </div>
      )}
      {action.strikethrough && (
        <div className="rounded-lg border border-emphasis p-1">
          <StrikeThroughIcon size={16} />
        </div>
      )}
    </div>
  );

  return (
    <>
      <SortableCardWrapper
        ref={displayRuleCardRef}
        sortableItemId={displayRule.key}
        isDragOverlay={isDragOverlay}
        isInactiveState={isInvalid}
        onEditClick={() => setIsEditRuleModalOpen(true)}
        onDuplicateClick={() => onRuleDuplicate(displayRule)}
        onDeleteClick={() => onRuleDelete(displayRule.key)}
      >
        <div className="mb-2 inline-flex items-center" data-testid={dataTestId}>
          <span className="text-xs font-medium text-emphasis">
            {t('components.display_rule_card.rule_number', {
              number: ruleNumber
            })}
          </span>

          {isInvalid && (
            <Tooltip>
              <Tooltip.Trigger asChild>
                <Badge intent="warning" className="ml-2 cursor-pointer leading-none">
                  {t('components.display_rule_card.invalid_rule_badge')}
                </Badge>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content className="max-w-80" side="bottom">
                  {t('components.display_rule_card.invalid_details_rule_tooltip')}
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip>
          )}
        </div>

        {displayRule.criteria.length === 0 && <EmptyRuleValue />}

        {displayRule.criteria.map((criteria, index) => {
          const criteriaField = availableViewFields.find((f) => f.key === criteria.field);
          const isLastCriteria = index === displayRule.criteria.length - 1;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`${criteria.field}-${index}`}>
              {criteriaField ? (
                <div className="flex flex-wrap items-center gap-1">
                  <span>{t('components.rules.when')}</span>
                  <InlineKnackField
                    fieldType={criteriaField.type}
                    fieldName={criteriaField.name}
                    className="border-none bg-action"
                  />
                  {t(`operators.${snakeCase(criteria.operator)}`)}
                  <InlineKnackCriteriaValue
                    criteria={criteria}
                    criteriaField={criteriaField}
                    className="bg-action"
                  />
                </div>
              ) : (
                <EmptyRuleValue />
              )}

              {!isLastCriteria && <div className="my-1">{t('components.rules.and_uppercase')}</div>}
            </Fragment>
          );
        })}

        {displayRule.actions.length === 0 && <EmptyRuleValue />}

        {displayRule.actions.map((action, index) => {
          const actionField = availableViewFields.find((f) => f.key === action.field);

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`${action.field}-${index}`}>
              {actionField ? (
                <div className="my-2 flex flex-wrap items-center gap-1">
                  <span>{t('components.rules.then')}</span>
                  {STATIC_TEXTS[action.action] || ''}
                  {(action.action === 'text-color' || action.action === 'bg-color') && (
                    <>
                      <div
                        style={{ backgroundColor: action.value || action.color }}
                        className="size-4 rounded-md"
                      />
                      <InlineKnackValue
                        value={action.value.toUpperCase() || action?.color?.toUpperCase() || ''}
                        className="bg-action"
                      />
                    </>
                  )}
                  {action.action === 'text-style' && renderTextStyleIcon(action)}
                  {action.action !== 'show' && action.action !== 'hide' && (
                    <span>{t('components.rules.for')}</span>
                  )}
                  {actionField && (
                    <InlineKnackField
                      fieldType={actionField.type}
                      fieldName={actionField.name}
                      className="border-none bg-action"
                    />
                  )}
                </div>
              ) : (
                <EmptyRuleValue />
              )}

              {index < displayRule.actions.length - 1 && (
                <div className="my-1">{t('components.rules.and_uppercase')}</div>
              )}
            </Fragment>
          );
        })}
      </SortableCardWrapper>

      {isEditRuleModalOpen && (
        <DetailsDisplayRuleFormDialog
          onOpenChange={setIsEditRuleModalOpen}
          displayRule={displayRule}
          sourceObject={sourceObject}
          onRuleSave={(updatedDisplayRule) => {
            setIsEditRuleModalOpen(false);
            onRuleSave(updatedDisplayRule);
          }}
        />
      )}
    </>
  );
}
