import { useState } from 'react';
import { useFieldArray, useForm, type FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPencil as EditIcon, HiPlus as PlusIcon } from 'react-icons/hi2';
import { DndContext, DragOverlay, type DragEndEvent, type DragStartEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';
import { z, type IssueData } from 'zod';

import { type KnackMenuFilter } from '@/types/schema/KnackFilter';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useDndUtils } from '@/hooks/useDndUtils';
import { VerticalListSortableItem } from '@/components/dnd/VerticalListSortableItem';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewSettingsMenuFiltersDialogFormItem } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsMenuFiltersDialogFormItem';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export interface CriteriaFormValueWithId extends KnackMenuFilter {
  id: string;
}

function ViewSettingsMenuFiltersDialogContent({
  filters,
  sourceObject,
  onFormSubmit
}: {
  filters: KnackMenuFilter[];
  sourceObject: KnackObject;
  onFormSubmit: (data: { menu_filters: KnackMenuFilter[] }) => void;
}) {
  const [t] = useTranslation();
  const { getDefaultCriteriaOperator, getDefaultCriteriaValue, validateCriteriaValues } =
    useCriteriaHelpers();

  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();
  const [beingDraggedSummaryId, setBeingDraggedSummaryId] = useState<string | null>(null);

  const defaultFiltersRulesSchema = z.object({
    menu_filters: z.custom<KnackMenuFilter[]>().superRefine((data, context) => {
      const criteriaValueErrors: { key: string; issue: IssueData }[] = [];
      data.forEach((criteria, criteriaIndex) => {
        const isLinkTextEmpty = !criteria.text.trim();
        const criteriaValueError = validateCriteriaValues([criteria], sourceObject.fields)[0];

        if (isLinkTextEmpty) {
          context.addIssue({
            // Add the criteria.key before the path so the error is shown in the correct card
            path: [`${criteria.key}:criteria.${criteriaIndex}.text`],
            message: t('errors.value_required'),
            code: 'custom'
          });
        }

        if (criteriaValueError) {
          criteriaValueErrors.push({ key: criteria.key, issue: criteriaValueError });
        }
      });

      if (criteriaValueErrors.length) {
        criteriaValueErrors.forEach((error) => {
          context.addIssue({
            path: [`${error.key}:${error.issue.path}`],
            message: t(error.issue.message || 'errors.value_required'),
            code: 'custom'
          });
        });
      }
    })
  });

  const form = useForm<{ menu_filters: KnackMenuFilter[] }>({
    resolver: zodResolver(defaultFiltersRulesSchema),
    defaultValues: {
      menu_filters: filters
    }
  });

  const {
    fields: menuFilters,
    remove: removeMenuFilter,
    append: addNewMenuFilter,
    move: moveMenuFilter
  } = useFieldArray({
    control: form.control,
    name: 'menu_filters'
  });

  const hasFilters = menuFilters.length > 0;
  const formErrors = form.formState.errors?.menu_filters as FieldErrors<{
    criteria: KnackMenuFilter[];
  }>;

  const handleDragStart = (event: DragStartEvent) => {
    setBeingDraggedSummaryId(event.active.id.toString());
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!menuFilters) {
      return;
    }

    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = menuFilters.findIndex((f) => f.key === active.id) ?? -1;
      const newIndex = menuFilters.findIndex((f) => f.key === over.id) ?? -1;

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      moveMenuFilter(oldIndex, newIndex);

      setBeingDraggedSummaryId(null);
    }
  };

  const getDefaultMenuFilter = () => {
    if (!sourceObject.fields) {
      return undefined;
    }

    if (sourceObject.fields.length === 0) {
      return undefined;
    }

    const firstFieldInObject = sourceObject.fields[0];

    const defaultMenuFilter: KnackMenuFilter = {
      key: `menu_filter_${nanoid(5)}`,
      text: '',
      field: firstFieldInObject.key,
      operator: getDefaultCriteriaOperator(firstFieldInObject, 'filter'),
      value: getDefaultCriteriaValue(firstFieldInObject)
    };

    return defaultMenuFilter;
  };

  const handleUpdateMenuFilterCriteria = (
    menuFilterIndex: number,
    criteria: CriteriaFormValueWithId
  ) => {
    // Remove the id from the criteria object created by useFieldArray in the criteria form. It's not needed in the final payload.
    const { id, ...cleanCriteria } = criteria;
    form.setValue(`menu_filters.${menuFilterIndex}`, cleanCriteria);
  };

  const onDuplicateMenuFilter = (menuFilterIndex: number) => {
    const duplicatedMenuFilter = menuFilters[menuFilterIndex];

    if (!duplicatedMenuFilter) {
      return;
    }

    addNewMenuFilter({ ...duplicatedMenuFilter, key: `menu_filter_${nanoid(5)}` });
  };

  return (
    <div className="w-full">
      <form onSubmit={form.handleSubmit(onFormSubmit)}>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {t(
                'pages.element_settings.common.categories.data_display.filtering_section.menu_links'
              )}
            </Dialog.Title>
            <Dialog.Description className="text-xs text-subtle">
              {t(
                'pages.element_settings.common.categories.data_display.filtering_section.default_filters_description'
              )}
            </Dialog.Description>
          </Dialog.Header>
          {hasFilters && (
            <p className="my-4">
              {t(
                'pages.element_settings.common.categories.data_display.general_settings.show_the_records_match'
              )}
            </p>
          )}
          <div>
            <DndContext
              sensors={optimizedSensors}
              collisionDetection={verticalListCollisionDetection}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={menuFilters.map((f) => f.key)}
                strategy={verticalListSortingStrategy}
              >
                <div className="mt-4 space-y-2">
                  {menuFilters.map((menuFilter, menuFilterIndex) => {
                    // Remove the id from the criteria object created by useFieldArray. It causes conflicts with the id created in CriteriaForm
                    const { id, ...menuFilterWithoutId } = menuFilter;

                    return (
                      <VerticalListSortableItem key={id} id={menuFilter.key}>
                        <ViewSettingsMenuFiltersDialogFormItem
                          menuFilter={menuFilterWithoutId}
                          menuFilterIndex={menuFilterIndex}
                          sourceObject={sourceObject}
                          handleUpdateMenuFilterCriteria={handleUpdateMenuFilterCriteria}
                          onDuplicateMenuFilter={onDuplicateMenuFilter}
                          onRemoveMenuFilter={removeMenuFilter}
                          formErrors={formErrors}
                        />
                      </VerticalListSortableItem>
                    );
                  })}
                </div>
              </SortableContext>
              <DragOverlay>
                {menuFilters.map((menuFilter, menuFilterIndex) => {
                  if (menuFilter.key !== beingDraggedSummaryId) {
                    return null;
                  }

                  return (
                    <ViewSettingsMenuFiltersDialogFormItem
                      key={menuFilter.key}
                      menuFilter={menuFilter}
                      menuFilterIndex={menuFilterIndex}
                      sourceObject={sourceObject}
                      handleUpdateMenuFilterCriteria={handleUpdateMenuFilterCriteria}
                      onDuplicateMenuFilter={onDuplicateMenuFilter}
                      onRemoveMenuFilter={removeMenuFilter}
                      formErrors={formErrors}
                    />
                  );
                })}
              </DragOverlay>
            </DndContext>
            <Button
              intent="minimal"
              className="gap-1"
              onClick={() => {
                const defaultMenuFilter = getDefaultMenuFilter();

                if (!defaultMenuFilter) {
                  return;
                }
                addNewMenuFilter(defaultMenuFilter);
              }}
            >
              <Button.Icon icon={PlusIcon} />
              {t(
                'pages.element_settings.common.categories.data_display.filtering_section.add_preset_filter'
              )}
            </Button>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button type="submit">{t('actions.apply')}</Button>
        </Dialog.Footer>
      </form>
    </div>
  );
}

export function ViewSettingsMenuFiltersDialog({ sourceObject, filters }) {
  const [t] = useTranslation();
  const { view } = useActiveViewContext();
  const updateViewSchema = useUpdateView();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDefaultFiltersSubmit = (data: { menu_filters: KnackMenuFilter[] }) => {
    if (view.type === 'map') {
      updateViewSchema({
        details: {
          ...view.details,
          menu_filters: data.menu_filters
        }
      });
      setIsDialogOpen(false);
      return;
    }

    updateViewSchema({
      menu_filters: data.menu_filters
    });
    setIsDialogOpen(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Trigger asChild>
        <Button intent="secondary">
          {filters.length > 0 ? (
            <EditIcon size={16} className="mr-1" />
          ) : (
            <PlusIcon size={16} className="mr-1" />
          )}
          {t('pages.element_settings.common.categories.data_display.filtering_section.menu_links')}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <ViewSettingsMenuFiltersDialogContent
          onFormSubmit={handleDefaultFiltersSubmit}
          filters={filters}
          sourceObject={sourceObject}
        />
      </Dialog.Content>
    </Dialog>
  );
}
