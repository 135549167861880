import { nanoid } from 'nanoid';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  type DetailsView,
  type DetailsViewDisplayRule,
  type DetailsViewDisplayRuleAction,
  type DetailsViewInput
} from '@/types/schema/views/DetailsView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { useViewHelpers } from '@/hooks/helpers/useViewHelpers';

export function useDetailsDisplayRulesHelpers({
  view,
  sourceObject
}: {
  view: DetailsView;
  sourceObject: KnackObject;
}) {
  const { getViewFields } = useViewHelpers();
  const { hasRoleObjects } = useObjectHelpers();
  const { getDefaultCriteriaOperator, getDefaultCriteriaValue } = useCriteriaHelpers();

  const getAvailableViewFieldsForDisplayRules = () =>
    getViewFields({
      view,
      sourceObject,

      // Ignore the user roles fields if no user roles exist in the application
      fieldTypesToIgnore: !hasRoleObjects() ? ['user_roles'] : undefined
    });

  const getInvalidDisplayRules = () => {
    const invalidDisplayRules: DetailsViewDisplayRule[] = [];
    const detailsViewFieldInput: DetailsViewInput[] = view.columns.flatMap((outerColumn) =>
      outerColumn.groups.flatMap((group) =>
        group.columns.flatMap((column) => column.filter((input) => input.type === 'field'))
      )
    );

    view.rules?.fields.forEach((displayRule) => {
      // If there are no field in the details view, the rule is automatically considered invalid.
      if (detailsViewFieldInput.length === 0) {
        invalidDisplayRules.push(displayRule);
      }

      // A rule is invalid if the criteria is empty, or if any criteria or actions reference a field that is not in the details view.
      const isInvalid =
        displayRule.criteria.length === 0 ||
        displayRule.criteria.some(
          (criteria) => !detailsViewFieldInput.some((field) => field?.key === criteria.field)
        ) ||
        displayRule.actions.some(
          (action) => !detailsViewFieldInput.some((field) => field?.key === action.field)
        );

      if (isInvalid) {
        invalidDisplayRules.push(displayRule);
      }
    });

    return invalidDisplayRules;
  };

  const getDefaultDisplayRuleCriteria = () => {
    if (!sourceObject.fields) {
      return undefined;
    }

    const firstAvailableFieldInView = sourceObject.fields[0];
    if (!firstAvailableFieldInView) {
      return undefined;
    }

    const defaultDisplayRuleCriteria: KnackCriteria = {
      field: firstAvailableFieldInView.key,
      operator: getDefaultCriteriaOperator(firstAvailableFieldInView, 'display-rule'),
      value: getDefaultCriteriaValue(firstAvailableFieldInView)
    };

    return defaultDisplayRuleCriteria;
  };

  const getDefaultDisplayRuleAction = (): DetailsViewDisplayRuleAction | undefined => {
    if (!sourceObject.fields) {
      return undefined;
    }

    const firstAvailableFieldInView = sourceObject.fields[0];

    if (!firstAvailableFieldInView) {
      return undefined;
    }

    const defaultDisplayRuleAction: DetailsViewDisplayRuleAction = {
      field: firstAvailableFieldInView.key,
      action: 'show',
      value: ''
    };

    return defaultDisplayRuleAction as DetailsViewDisplayRuleAction;
  };

  const getDefaultDisplayRule = () => {
    const defaultDisplayRuleCriteria = getDefaultDisplayRuleCriteria();
    const defaultDisplayRuleAction = getDefaultDisplayRuleAction();

    const defaultDisplayRule: DetailsViewDisplayRule = {
      key: `display_${nanoid(10)}` as KnackFieldKey,
      criteria: defaultDisplayRuleCriteria ? [defaultDisplayRuleCriteria] : [],
      actions: defaultDisplayRuleAction ? [defaultDisplayRuleAction] : []
    };

    return defaultDisplayRule;
  };

  return {
    getAvailableViewFieldsForDisplayRules,
    getDefaultDisplayRule,
    getDefaultDisplayRuleCriteria,
    getDefaultDisplayRuleAction,
    getInvalidDisplayRules
  };
}
