import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { ResizableCollapsiblePanel } from '@/components/layout/ResizableCollapsiblePanel';
import { NavigateAwayModal } from '@/components/NavigateAwayModal';
import { PageEditorUiOptionsContextProvider } from '@/pages/pages/page-editor/PageEditorUiOptionsContext';
import { usePagesPageContext } from '@/pages/pages/PagesPageContext';
import { SettingsPanel } from '@/pages/pages/settings-panel/SettingsPanel';
import { ROUTES } from '@/Router';
import { AddViewModal } from './add-view/AddViewModal';
import { PageEditorContextProvider, usePageEditorContext } from './PageEditorContext';
import { PageEditorLiveAppFrame } from './PageEditorLiveAppFrame';
import { PageEditorTopBar } from './PageEditorTopBar';
import { PageEmptyState } from './PageEmptyState';

function PageEditorContent() {
  const [t] = useTranslation();
  const { isPageModified } = usePageEditorContext();
  const { isSettingsPanelOpen, setIsSettingsPanelOpen } = usePagesPageContext();

  return (
    <>
      <div className="flex h-full flex-col">
        <PageEditorTopBar />
        <div className="flex flex-1">
          <ResizableCollapsiblePanel
            panelContent={<SettingsPanel />}
            mainContent={<PageEditorLiveAppFrame />}
            autoSaveId="settings-panel"
            position="right"
            title={t('pages.element_settings.heading')}
            shouldForceOpen={isSettingsPanelOpen}
            onToggleCollapse={(isCollapsed) => {
              setIsSettingsPanelOpen(!isCollapsed);
            }}
          />
        </div>
      </div>
      <AddViewModal />
      <NavigateAwayModal condition={isPageModified} />
    </>
  );
}

export function PageEditor({ isIndex = false }: { isIndex?: boolean }) {
  const { pages, activePage, activePageSourceObjectRecords } = usePagesPageContext();

  if (isIndex) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <PageEmptyState emptyStateKey={pages.length === 0 ? 'no-pages' : 'select-page'} />
      </div>
    );
  }

  if (!activePage) {
    return <Navigate to={ROUTES.PAGES} replace />;
  }

  // Menu pages are not editable/accessible
  if (activePage.type === 'menu') {
    return <Navigate to={ROUTES.PAGES} replace />;
  }

  return (
    <PageEditorUiOptionsContextProvider>
      <PageEditorContextProvider
        activePage={activePage}
        activePageSourceObjectRecords={activePageSourceObjectRecords}
      >
        <PageEditorContent />
      </PageEditorContextProvider>
    </PageEditorUiOptionsContextProvider>
  );
}
