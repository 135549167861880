import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

type AddOptionProps = {
  onAddOption: () => void;
};

export function AddOption({ onAddOption }: AddOptionProps) {
  const [t] = useTranslation();
  return (
    <Button className="p-1" data-testid="add-option-button" intent="minimal" onClick={onAddOption}>
      <PlusIcon size={24} className="mr-2" />
      {t('components.data_table.attributes.field_settings.multiple_choice.add_option')}
    </Button>
  );
}
