/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { cn } from '@/utils/tailwind';

export type InfoItemProps = {
  animationDelay: number;
  index?: number;
  icon: React.ReactNode;
  renderTitle: (searchParams: URLSearchParams) => React.ReactNode;
  renderDescription?: (searchParams: URLSearchParams) => React.ReactNode;
};

export function InfoItem({
  animationDelay,
  index,
  icon,
  renderTitle,
  renderDescription
}: InfoItemProps) {
  const [searchParams] = useSearchParams();

  const titleComponent = useMemo(() => renderTitle(searchParams), [renderTitle]);
  const descriptionComponent = useMemo(
    () => (renderDescription ? renderDescription(searchParams) : null),
    [renderDescription]
  );

  return (
    <div
      style={{ animationDelay: `${animationDelay}ms` }}
      className={cn(
        'flex w-full animate-slideDownAndFade gap-2 rounded-lg bg-muted p-2 px-3 opacity-0 fill-mode-forwards',
        {
          'min-h-[72px] items-center gap-3 px-4': !renderDescription
        }
      )}
    >
      {icon}
      <div
        className={cn('flex flex-col gap-1', {
          'pb-1 pt-0.5': !!renderDescription
        })}
      >
        <p
          className={cn({
            'text-md': !renderDescription
          })}
          data-testid={`welcome-info-${index}-title`}
        >
          {titleComponent}
        </p>
        {descriptionComponent && (
          <p className="text-xs text-subtle" data-testid={`welcome-info-${index}-description`}>
            {descriptionComponent}
          </p>
        )}
      </div>
    </div>
  );
}
