import { type KnackField } from '@/types/schema/KnackField';
import { type RecordRuleValue } from '@/types/schema/rules/RecordRule';

// If the rule value is from a field that supports multiselection, we need to determine the action for the values (e.g. replace, add, remove)
// If a field supports multiselection, it can be one of the following:
// - Multiple choice field with a multi select format (e.g. 'multi' or 'checkboxes')
// - Connection field with a 'has' relationship set to 'many'
export function getRuleMultiSelectValuesAction(
  ruleValue: RecordRuleValue,
  connectedField: KnackField
) {
  const isMultipleChoiceFieldWithMultiSelect =
    connectedField.type === 'multiple_choice' &&
    (connectedField.format.type === 'multi' || connectedField.format.type === 'checkboxes');

  const isConnectionFieldWithMultiSelect =
    connectedField.type === 'connection' && connectedField.relationship?.has === 'many';

  const shouldShowMultiSelectAction =
    ruleValue.type === 'value' &&
    (isMultipleChoiceFieldWithMultiSelect || isConnectionFieldWithMultiSelect);

  return shouldShowMultiSelectAction ? ruleValue.action || 'replace' : undefined;
}
