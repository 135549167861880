import { t as i18nT } from 'i18next';

import { type RecordRuleActionType } from '@/types/schema/rules/RecordRule';

export type RecordRuleFormFieldsLabels = {
  actionType: string;
  values: string;
  runEveryTime?: string;
};

export function getRecordRuleLabels(
  ruleActionType: RecordRuleActionType,
  labels: Partial<RecordRuleFormFieldsLabels> | undefined
) {
  const defaultLabels: RecordRuleFormFieldsLabels = {
    actionType: i18nT('components.rules.record_rules.on_submission'),
    values:
      ruleActionType === 'insert'
        ? i18nT('components.record_rule_card.field_value')
        : i18nT('components.record_rule_card.update_field_value')
  };

  return {
    ...defaultLabels,
    ...labels
  };
}
