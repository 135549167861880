import { useEffect } from 'react';
import Intercom, { boot, shutdown, update } from '@intercom/messenger-js-sdk';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';

export function shouldInstallIntercom() {
  return import.meta.env.PUBLIC_INTERCOM_APP_ID !== '';
}

type IntercomProviderProps = {
  children: React.ReactNode;
};

export function IntercomProvider({ children }: IntercomProviderProps) {
  const { data: application } = useApplicationQuery();
  const { data: session, isPlaceholderData: isSessionPlaceholderData } = useSessionQuery();
  const isAuthenticated = !!session?.user.id;

  // This is meant to be called on every render as detailed by the package documentation:
  // > The package keeps track of any instances needed internally, therefore re-renders due
  // > to DOM changes won't trigger re-boot of the messenger.
  // See https://www.npmjs.com/package/@intercom/messenger-js-sdk
  useEffect(() => {
    Intercom({
      app_id: import.meta.env.PUBLIC_INTERCOM_APP_ID
    });
  });

  useEffect(() => {
    if (!application || isSessionPlaceholderData) {
      return;
    }

    if (isAuthenticated) {
      boot({
        app_id: import.meta.env.PUBLIC_INTERCOM_APP_ID
      });
      update({
        name: `${session.user.firstName ?? ''} ${session.user.lastName ?? ''}`.trim(),
        email: session.user.email,
        user_id: session.user.id,
        account: application.account.slug
      });
    } else {
      shutdown();
    }
  }, [application, isAuthenticated, isSessionPlaceholderData, session]);

  return children;
}
