import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { Banner, Table } from '@knack/asterisk-react';
import { flexRender, getCoreRowModel, useReactTable, type ColumnDef } from '@tanstack/react-table';
import { AnimatePresence, motion } from 'framer-motion';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { FieldIcon } from '@/components/FieldIcon';
import { type ColumnMeta } from '@/components/import/types';
import { HEADER_HEIGHT } from '@/components/layout/Header';
import { useImportStore } from './useImportStore';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

export function PreviewTable<TData, TValue>({ columns, data }: DataTableProps<TData, TValue>) {
  const [t] = useTranslation();
  const { columnVisibility, getTotalVisibleColumns } = useImportStore((state) => ({
    columnVisibility: state.columnVisibility,
    getTotalVisibleColumns: state.getTotalVisibleColumns
  }));
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility
    }
  });

  const totalVisibleColumns = getTotalVisibleColumns();

  const TableHeadAnimated = useMemo(() => motion(Table.Head, { forwardMotionProps: true }), []);
  const TableCellAnimated = useMemo(() => motion(Table.Cell, { forwardMotionProps: true }), []);

  if (!columns || totalVisibleColumns === 0) {
    return (
      <div className="p-6">
        <Banner intent="destructive" icon={ExclamationIcon}>
          {t('components.add_table.no_columns_selected')}
        </Banner>
      </div>
    );
  }

  return (
    <div
      className="ml-6 mt-6 w-full overflow-auto rounded border-0 border-t"
      // 50 is the height of the table margins
      style={{ height: `calc(100vh - ${HEADER_HEIGHT * 2 + 25}px)` }}
    >
      <Table
        className="import-preview-table border-collapse border bg-base"
        data-testid="import-preview-table"
      >
        <Table.Header
          className="sticky top-[-1px] bg-muted"
          data-testid="import-preview-table-header"
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              <AnimatePresence initial={false}>
                {headerGroup.headers.map((header) => (
                  <TableHeadAnimated
                    key={header.id}
                    className="overflow-hidden text-ellipsis whitespace-nowrap border"
                    initial={{ maxWidth: 0, padding: '0 1rem', opacity: 0 }}
                    animate={{ maxWidth: 448, opacity: 1 }}
                    transition={{ duration: 0.4 }}
                    exit={{ opacity: 0, maxWidth: 0, padding: 0, x: -100 }}
                  >
                    <span className="flex items-center">
                      <FieldIcon
                        size={16}
                        name={
                          (header.column.columnDef.meta as ColumnMeta)
                            ?.newFieldType as KnackFieldType
                        }
                        className="mr-1.5 text-default"
                      />
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </span>
                  </TableHeadAnimated>
                ))}
              </AnimatePresence>
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body data-testid="import-preview-table-body">
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <Table.Row key={row.id} data-state={row.getIsSelected() && 'selected'}>
                <AnimatePresence initial={false}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCellAnimated
                      key={cell.id}
                      className="overflow-hidden text-ellipsis whitespace-nowrap border"
                      initial={{ padding: '1rem', maxWidth: 0 }}
                      transition={{
                        duration: 0.4
                      }}
                      animate={{ maxWidth: 448 }}
                      exit={{ opacity: 0, maxWidth: 0, padding: 0, x: -100, whiteSpace: 'nowrap' }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCellAnimated>
                  ))}
                </AnimatePresence>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={columns.length} className="h-24 text-center">
                {t('components.add_table.no_results')}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  );
}
