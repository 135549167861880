import { useLayoutEffect, useRef, useState } from 'react';

export function ConfettiAnimation() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [animationActive, setAnimationActive] = useState(true);

  useLayoutEffect(() => {
    const confettiCanvas = canvasRef.current;

    if (!confettiCanvas) return;

    const ctx = confettiCanvas.getContext('2d');

    confettiCanvas.width = window.innerWidth;
    confettiCanvas.height = window.innerHeight;

    const colors = ['#FF007A', '#7A00FF', '#00FF7A', '#FFD700', '#00D4FF'];

    const confetti = Array.from({ length: 200 }, () => ({
      x: Math.random() * confettiCanvas.width,
      y: Math.random() * confettiCanvas.height - confettiCanvas.height,
      size: Math.random() * 10 + 5,
      color: colors[Math.floor(Math.random() * colors.length)],
      speedX: Math.random() * 6 - 3,
      speedY: Math.random() * 12 + 4,
      rotation: Math.random() * 360
    }));

    function animateConfetti() {
      if (!ctx || !confettiCanvas) return;

      ctx.clearRect(0, 0, confettiCanvas.width, confettiCanvas.height);

      let particlesRemaining = 0;

      confetti.forEach((particle) => {
        particle.x += particle.speedX;
        particle.y += particle.speedY;
        particle.rotation += particle.speedX;

        ctx.save();
        ctx.translate(particle.x, particle.y);
        ctx.rotate((particle.rotation * Math.PI) / 180);
        ctx.fillStyle = particle.color;
        ctx.fillRect(-particle.size / 2, -particle.size / 2, particle.size, particle.size);
        ctx.restore();

        if (particle.y <= confettiCanvas.height) {
          particlesRemaining += 1;
        }
      });

      if (particlesRemaining > 0) {
        requestAnimationFrame(animateConfetti);
      } else {
        setAnimationActive(false);
      }
    }

    animateConfetti();
  }, []);

  if (!animationActive) {
    return null;
  }

  return <canvas ref={canvasRef} className="pointer-events-none absolute inset-0 z-10" />;
}
