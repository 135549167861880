import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon, HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Button, Select } from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackConnectionWithObject, type KnackObject } from '@/types/schema/KnackObject';
import {
  RECORD_RULE_MULTI_SELECT_ACTION_TYPES,
  type RecordRule,
  type RecordRuleValue,
  type RecordRuleValueType
} from '@/types/schema/rules/RecordRule';
import { useRecordRuleHelpers } from '@/hooks/helpers/useRecordRuleHelpers';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { FieldIcon } from '@/components/FieldIcon';
import { RecordRuleValueInput } from '@/components/record-rule/RecordRuleValueInput';

interface RecordRuleDialogGroupItemProps {
  ruleValuesObject: KnackObject;
  sourceObject: KnackObject;
  sourceObjectConnections: KnackConnectionWithObject[];
  ruleAllowedValueTypes?: RecordRuleValueType[];
  sourcePage?: BuilderPage;
}

export function RecordRuleValueGroup({
  ruleValuesObject,
  sourceObject,
  sourceObjectConnections,
  ruleAllowedValueTypes,
  sourcePage
}: RecordRuleDialogGroupItemProps) {
  const [t] = useTranslation();

  const {
    getEligibleFieldsAsRecordRuleValueTarget,
    getNewDefaultRecordRuleValue,
    getDefaultRecordRuleValue,
    getRuleConnectedValueOptions,
    getRuleRecordValueFieldOptions,
    getRuleValueTypeOptions
  } = useRecordRuleHelpers();

  const {
    control,
    getValues,
    clearErrors,
    formState: { errors }
  } = useFormContext<RecordRule>();

  const {
    append,
    update,
    remove,
    fields: recordRuleValues
  } = useFieldArray({
    control,
    name: 'values'
  });

  const appendNewRecordRuleValue = () => {
    append(
      getDefaultRecordRuleValue(getValues('connection'), getValues('action'), ruleValuesObject)
    );
  };

  // Populate the `values` array if there are no values
  if (recordRuleValues.length === 0) {
    appendNewRecordRuleValue();
  }

  const eligibleTargetFields = getEligibleFieldsAsRecordRuleValueTarget(
    getValues('connection'),
    getValues('action'),
    ruleValuesObject
  );

  return recordRuleValues.map((ruleValue, ruleValueIndex) => {
    const selectedField = eligibleTargetFields.find((f) => f.key === ruleValue.field);

    if (!selectedField) {
      return null;
    }

    const ruleConnectedValueOptions = getRuleConnectedValueOptions(
      selectedField,
      sourceObject,
      sourceObjectConnections
    );

    const ruleRecordValueFieldOptions = getRuleRecordValueFieldOptions(selectedField, sourceObject);

    const ruleValueTypeOptions = getRuleValueTypeOptions(
      selectedField,
      ruleConnectedValueOptions.length > 0,
      sourcePage,
      ruleAllowedValueTypes
    );

    const isMultipleChoiceFieldWithMultiSelect =
      selectedField.type === 'multiple_choice' &&
      (selectedField.format.type === 'multi' || selectedField.format.type === 'checkboxes');

    const isConnectionFieldWithMultiSelect =
      selectedField.type === 'connection' && selectedField.relationship?.has === 'many';

    const shouldShowMultiSelectActionDropdown =
      ruleValue.type === 'value' &&
      (isMultipleChoiceFieldWithMultiSelect || isConnectionFieldWithMultiSelect);

    return (
      <div key={ruleValue.id} className="flex">
        <div className="min-w-0 flex-1 space-y-2">
          <div className="flex gap-2">
            <div className="max-w-1/2 -m-1 flex-1 overflow-hidden p-1">
              <Controller
                control={control}
                name={`values.${ruleValueIndex}.field`}
                render={({ field: { value: fieldKey } }) => {
                  const isValidFieldKey = eligibleTargetFields.some((f) => f.key === fieldKey);

                  return (
                    <Select
                      value={isValidFieldKey ? fieldKey : undefined}
                      onValueChange={(newFieldKey: KnackFieldKey) => {
                        update(ruleValueIndex, {
                          ...ruleValue,
                          field: newFieldKey,
                          type: 'value',
                          value: ''
                        });
                        clearErrors();
                      }}
                      disabled={eligibleTargetFields.length === 0}
                    >
                      <Select.Trigger
                        placeholder={t('actions.select')}
                        className={cn('w-full', {
                          'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                            errors.values?.[ruleValueIndex]?.field
                        })}
                      />
                      <Select.Content className="min-w-[200px]">
                        {eligibleTargetFields.map((field) => (
                          <Select.Item key={field.key} value={field.key}>
                            <span className="flex items-center">
                              <FieldIcon
                                className="mr-2 shrink-0 text-subtle"
                                size={16}
                                name={field.type}
                              />
                              <span className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                                {field.name}
                              </span>
                            </span>
                          </Select.Item>
                        ))}
                      </Select.Content>
                    </Select>
                  );
                }}
              />
              <FormErrorMessage
                errors={errors}
                name={`values.${ruleValueIndex}.field`}
                className="mt-1"
              />
            </div>
            <div className="max-w-1/2 -m-1 flex-1 overflow-hidden p-1">
              <Controller
                control={control}
                name={`values.${ruleValueIndex}.type`}
                render={({ field: { value: valueType, onChange } }) => (
                  <Select
                    value={valueType}
                    onValueChange={(newValueType: RecordRuleValue['type']) => {
                      update(
                        ruleValueIndex,
                        getNewDefaultRecordRuleValue(
                          getValues(`values.${ruleValueIndex}`),
                          newValueType,
                          selectedField,
                          sourceObject,
                          ruleConnectedValueOptions
                        )
                      );
                      onChange(newValueType);
                      clearErrors();
                    }}
                  >
                    <Select.Trigger placeholder={`${t('actions.select')}...`} className="w-full" />
                    <Select.Content className="min-w-52">
                      {ruleValueTypeOptions.map((ruleValueType) => {
                        // Disable the `record` ("to a field value") option if the selected target field is not compatible with the field option.
                        // e.g. if we have an Image field, we can only update it with another Image field.
                        const isOptionDisabled =
                          ruleValueType === 'record' && ruleRecordValueFieldOptions.length === 0;

                        return (
                          <Select.Item
                            key={ruleValueType}
                            value={ruleValueType}
                            disabled={isOptionDisabled}
                          >
                            {t(`components.rules.record_rules.value_types.${ruleValueType}`)}
                          </Select.Item>
                        );
                      })}
                    </Select.Content>
                  </Select>
                )}
              />
            </div>
          </div>
          {shouldShowMultiSelectActionDropdown && (
            <Controller
              control={control}
              name={`values.${ruleValueIndex}.action`}
              render={({ field: { value: actionType, onChange } }) => (
                <Select
                  value={actionType || 'replace'}
                  onValueChange={(val) => {
                    if (val === 'replace') {
                      onChange('');
                      return;
                    }
                    onChange(val);
                  }}
                >
                  <Select.Trigger className="w-full" defaultValue="replace" />
                  <Select.Content>
                    <Select.Item value="replace">
                      {t(`components.rules.record_rules.multi_select_value_action_types.replacing`)}
                    </Select.Item>
                    {RECORD_RULE_MULTI_SELECT_ACTION_TYPES.map((type) => (
                      <Select.Item key={type} value={type}>
                        {t(`components.rules.record_rules.multi_select_value_action_types.${type}`)}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
              )}
            />
          )}
          {(ruleValue.type === 'record' ||
            ruleValue.type === 'value' ||
            ruleValue.type === 'connection') && (
            <RecordRuleValueInput
              selectedField={selectedField}
              ruleActionType={ruleValue.type}
              ruleConnectedValueOptions={ruleConnectedValueOptions}
              ruleRecordValueFieldOptions={ruleRecordValueFieldOptions}
              formFieldName={`values.${ruleValueIndex}`}
              sourceObject={ruleValuesObject}
            />
          )}
        </div>
        <div className="flex">
          <Button
            intent="minimal"
            aria-label={t('components.rules.delete_condition')}
            size="xs"
            className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
            onClick={() => remove(ruleValueIndex)}
            disabled={recordRuleValues.length < 2}
          >
            <RemoveIcon size={16} />
          </Button>
          <Button
            intent="minimal"
            aria-label={t('components.rules.delete_condition')}
            size="xs"
            className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
            onClick={appendNewRecordRuleValue}
          >
            <AddIcon size={16} />
          </Button>
        </div>
      </div>
    );
  });
}
