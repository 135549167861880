/* eslint-disable react/no-array-index-key */

import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';

import { useValidateQueryParam } from '@/hooks/useValidateQueryParam';
import { FullPageSpinner } from '@/components/FullPageSpinner';
import { ConfettiAnimation } from '@/pages/welcome/ConfettiAnimation';
import { InfoItem } from '@/pages/welcome/InfoItem';
import { TemplateButton } from '@/pages/welcome/TemplateButton';
import { useWelcomeData, welcomeTypes } from '@/pages/welcome/useWelcomeData';
import { WelcomeLink } from '@/pages/welcome/WelcomeLink';
import { ROUTES } from '@/Router';

const DELAY_BETWEEN_ITEMS = 1000;

export function WelcomePage() {
  const [t] = useTranslation();
  const [searchParams] = useSearchParams();
  const type = useValidateQueryParam('type', welcomeTypes);
  const { data, isLoading } = useWelcomeData(type);

  if (!type) {
    return <Navigate to={ROUTES.ROOT} replace />;
  }

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <div className="mx-auto flex h-screen max-w-[472px] flex-col items-center gap-8 pt-[10%]">
      <ConfettiAnimation />
      <div className="flex animate-slideDownAndFade flex-col gap-4 opacity-0 delay-1000 fill-mode-forwards">
        <h2 className="text-center text-2xl font-medium text-emphasis">
          <Trans
            i18nKey="components.welcome.title"
            values={{ appName: searchParams.get('app-name') }}
          >
            <b data-testid="welcome-title-app-name" />
          </Trans>
        </h2>
        <p className="text-center text-subtle">{t('components.welcome.description')}</p>
      </div>

      <div className="flex w-full flex-col gap-4">
        {data.map((item, index) => (
          <InfoItem
            key={index}
            index={index}
            animationDelay={(index + 2) * DELAY_BETWEEN_ITEMS}
            icon={item.icon}
            renderTitle={item.renderTitle}
            renderDescription={item.renderDescription}
          />
        ))}
      </div>

      {type === welcomeTypes.template ? (
        <TemplateButton animationDelay={(data.length + 2) * DELAY_BETWEEN_ITEMS} />
      ) : (
        <WelcomeLink
          animationDelay={(data.length + 2) * DELAY_BETWEEN_ITEMS}
          href={`${ROUTES.PAGES}?quickView=true`}
        >
          {t('components.welcome.ai.button')}
        </WelcomeLink>
      )}
    </div>
  );
}
