import { Trans, useTranslation } from 'react-i18next';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type RecordRule } from '@/types/schema/rules/RecordRule';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackObject } from '@/components/InlineKnackObject';
import { InlineKnackRecordValue } from '@/components/InlineKnackRecordValue';
import { getRuleMultiSelectValuesAction } from '@/pages/pages/settings-panel/view-settings/common/record-rules/helpers';
import { ViewRecordRuleCardMultiValueContent } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleCardMultiValueContent';

interface ViewRecordRuleCardConnectionContentProps {
  recordRule: RecordRule;
  sourceObject: KnackObject;
}

export function ViewRecordRuleCardConnectionContent({
  recordRule,
  sourceObject
}: ViewRecordRuleCardConnectionContentProps) {
  const [t] = useTranslation();
  const { getFieldByKey } = useFieldHelpers();

  if (!recordRule.connection) {
    return null;
  }

  const connectionKey = recordRule.connection.split('.');
  const connectionObjectKey = connectionKey[0] as KnackObject['key'];
  const connectionFieldKey = connectionKey[1] as KnackFieldKey;

  let connectionLabelKey = 'components.record_rule_card.insert_new_connected_record';

  if (recordRule.action !== 'insert') {
    const connectionField = getFieldByKey(connectionFieldKey);

    if (connectionField?.type === 'connection') {
      const isOutgoingConnection = sourceObject.fields.some((f) => f.key === connectionFieldKey);
      const isSingleConnection = isOutgoingConnection
        ? connectionField.relationship.has === 'one'
        : connectionField.relationship.belongs_to === 'one';

      connectionLabelKey = isSingleConnection
        ? 'components.record_rule_card.update_the_record_connected_to_this_record'
        : 'components.record_rule_card.update_each_record_connected_to_this_record';
    }
  }

  return (
    <div className="flex flex-wrap items-center gap-1">
      <p>
        <Trans
          i18nKey={connectionLabelKey}
          components={[<InlineKnackObject key="0" objectKey={connectionObjectKey} />]}
        />
      </p>
      {recordRule.values.map((ruleValue, valueIndex) => {
        const connectedField = getFieldByKey(ruleValue.field);
        const inputField =
          ruleValue.type === 'record' && ruleValue.input
            ? getFieldByKey(ruleValue.input as KnackFieldKey)
            : null;
        const connectionTargetFieldKey =
          ruleValue.type === 'connection' && ruleValue.connection_field
            ? (ruleValue.connection_field.split('-')[1] as KnackFieldKey)
            : null;

        const connectedTargetField = connectionTargetFieldKey
          ? getFieldByKey(connectionTargetFieldKey)
          : null;

        if (!connectedField) {
          return null;
        }

        const ruleMultiSelectValuesAction = getRuleMultiSelectValuesAction(
          ruleValue,
          connectedField
        );

        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${ruleValue.field}-${ruleValue.value}-${valueIndex}`}
            className="flex flex-wrap items-center gap-1"
          >
            {ruleMultiSelectValuesAction ? (
              <ViewRecordRuleCardMultiValueContent
                ruleAction={recordRule.action}
                ruleMultiSelectValuesAction={ruleMultiSelectValuesAction}
                ruleValue={ruleValue}
                field={connectedField}
              />
            ) : (
              <>
                {valueIndex === 0 && <p>{t('components.record_rule_card.with_the_value_of')}</p>}
                {recordRule.values.length > 1 && valueIndex > 0 && (
                  <span>{t('components.rules.and')}</span>
                )}
                <InlineKnackField
                  fieldName={connectedField.name}
                  fieldType={connectedField.type}
                  className="border-none bg-action"
                />
                <span>{t('keywords.to')}</span>
                {ruleValue.type === 'record' && inputField && (
                  <InlineKnackField
                    fieldName={inputField.name}
                    fieldType={inputField.type}
                    className="border-none bg-action"
                  />
                )}
                {ruleValue.type === 'connection' &&
                  ruleValue.connection_field &&
                  connectedTargetField && (
                    <InlineKnackField
                      fieldName={connectedTargetField.name}
                      fieldType={connectedTargetField.type}
                      className="border-none bg-action"
                    />
                  )}
                {ruleValue.type === 'value' && (
                  <InlineKnackRecordValue
                    value={ruleValue.value || '-'}
                    className="border-none bg-action"
                    field={connectedField}
                  />
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}
