import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi';
import { Button, Divider, Label, RadioGroup } from '@knack/asterisk-react';
import isEmpty from 'lodash.isempty';

import { MatchRuleCard } from '@/components/import/advanced-options/MatchRuleCard';
import { useImportStore } from '@/components/import/useImportStore';
import { getNonMatchableKnackFields } from '@/components/import/utils';
import { HEADER_HEIGHT } from '@/components/layout/Header';

export function AdvancedOptions() {
  const {
    existingTable,
    shouldUpdateExistingRecords,
    setShouldUpdateExistingRecords,
    shouldSkipImportIfNoMatch,
    setShouldSkipImportIfNoMatch,
    matchRules,
    setMatchRules
  } = useImportStore((state) => ({
    existingTable: state.existingTable,
    shouldUpdateExistingRecords: state.shouldUpdateExistingRecords,
    setShouldUpdateExistingRecords: state.setShouldUpdateExistingRecords,
    shouldSkipImportIfNoMatch: state.shouldSkipImportIfNoMatch,
    setShouldSkipImportIfNoMatch: state.setShouldSkipImportIfNoMatch,
    matchRules: state.matchRules,
    setMatchRules: state.setMatchRules
  }));
  const [t] = useTranslation();
  const [ruleIdCounter, setRuleIdCounter] = useState(0);

  const matchableFields = existingTable?.fields.filter(
    (field) => !getNonMatchableKnackFields().includes(field.type)
  );

  const defaultMatchableFieldKey = matchableFields?.[0]?.key;

  return (
    <div className="pt-12" data-testid="import-advanced-options-section">
      <div className="relative h-full grow">
        <div
          className="absolute inset-0 overflow-y-auto px-6 pb-20"
          style={{ height: `calc(100vh - ${HEADER_HEIGHT * 2}px)` }}
        >
          <h2 className="mb-6 font-semibold">
            {t('components.add_into_existing_table.advanced_import_options')}
          </h2>
          <Label className="w-full break-words">
            {t('components.add_into_existing_table.update_existing_table_records', {
              table_name: existingTable?.name
            })}
          </Label>
          <RadioGroup
            className="mt-2"
            defaultValue={shouldUpdateExistingRecords ? 'yes' : 'no'}
            onValueChange={(value) => {
              setShouldUpdateExistingRecords(value === 'yes');
              if (isEmpty(matchRules)) {
                setMatchRules([
                  {
                    ruleId: ruleIdCounter,
                    columnIndex: 0,
                    fieldKey: defaultMatchableFieldKey
                  }
                ]);
              }
            }}
          >
            <RadioGroup.Container>
              <RadioGroup.Item id="no-radio" value="no" />
              <Label htmlFor="no-radio">
                {t('components.add_into_existing_table.no_as_new_record')}
              </Label>
            </RadioGroup.Container>
            <RadioGroup.Container>
              <RadioGroup.Item
                id="yes-radio"
                value="yes"
                data-testid="import-advanced-options-update-existing-records"
              />
              <Label htmlFor="yes-radio">{t('components.add_into_existing_table.yes')}</Label>
            </RadioGroup.Container>
          </RadioGroup>
          {shouldUpdateExistingRecords && (
            <>
              <div className="mt-4" data-testid="import-advanced-options-extended-options">
                <Label className="mb-2 block">
                  {t('components.add_into_existing_table.update_records_fields_match')}
                </Label>
                <div className="mb-2 mt-1 rounded-lg bg-muted p-4">
                  <div className="grid grid-cols-2">
                    <Label className="mb-1 block">
                      {t('components.add_into_existing_table.imported_column')}
                    </Label>
                    <Label className="mb-1 block">
                      {t('components.add_into_existing_table.knack_field')}
                    </Label>
                  </div>
                  {matchRules.map((rule) => (
                    <MatchRuleCard key={rule.ruleId} id={rule.ruleId} />
                  ))}
                  <Button
                    intent="minimal"
                    data-testid="import-advanced-options-add-rule-button"
                    onClick={() => {
                      const newRuleId = ruleIdCounter + 1;
                      setRuleIdCounter(newRuleId);
                      setMatchRules([
                        ...matchRules,
                        {
                          ruleId: newRuleId,
                          columnIndex: 0,
                          fieldKey: defaultMatchableFieldKey
                        }
                      ]);
                    }}
                  >
                    <Button.Icon icon={PlusIcon} position="left" />
                    {t('components.add_into_existing_table.add_rule')}
                  </Button>
                </div>
              </div>
              <Divider className="mb-4 mt-4" />
              <Label>{t('components.add_into_existing_table.no_record_found')}</Label>
              <RadioGroup
                className="mt-2"
                defaultValue={shouldSkipImportIfNoMatch ? 'skip' : 'insert'}
                onValueChange={(value) => setShouldSkipImportIfNoMatch(value === 'skip')}
              >
                <RadioGroup.Container>
                  <RadioGroup.Item id="insert-radio" value="insert" />
                  <Label htmlFor="insert-radio">
                    {t('components.add_into_existing_table.insert_as_new_record')}
                  </Label>
                </RadioGroup.Container>
                <RadioGroup.Container>
                  <RadioGroup.Item id="skip-radio" value="skip" />
                  <Label htmlFor="skip-radio">
                    {t('components.add_into_existing_table.skip_and_do_nothing')}
                  </Label>
                </RadioGroup.Container>
              </RadioGroup>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
