import { type BuilderViewBase, type BuilderViewSourceSchema } from '@/types/schema/BuilderView';
import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackFilter, type KnackMenuFilter } from '@/types/schema/KnackFilter';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type RecordRule } from '@/types/schema/rules/RecordRule';
import { type DetailsView } from '@/types/schema/views/DetailsView';
import { type FormView } from '@/types/schema/views/FormView';

export interface TableView extends BuilderViewBase {
  type: 'table';
  columns: TableViewColumn[];
  links: unknown[];
  inputs: unknown[];
  groups: unknown[];
  keyword_search: boolean;
  rows_per_page: string;
  allow_exporting: boolean;
  allow_limit: boolean;
  allow_preset_filters: boolean;
  filter_type: 'fields' | 'menu' | 'none';
  menu_filters?: KnackMenuFilter[];
  filter_fields: 'view' | 'object';
  source: TableViewSourceSchema;
  options?: {
    cell_editor?: boolean;
  };
  totals: TableViewColumnSummary[];
  preset_filters: KnackFilter[];
  table_design_active: boolean;
  keyword_search_fields: 'view' | 'all';
  no_data_text: string;
  hide_empty: boolean;
}

export interface TableViewColumnSummary {
  label: string;
  calc: string;
}

export interface TableViewSourceSchema extends BuilderViewSourceSchema {
  limit?: string;
}

export interface TableViewColumn {
  id: string;
  action_rules?: TableViewActionRule[];
  grouping: boolean;
  group_sort: string;
  ignore_edit: boolean;
  ignore_summary: boolean;
  conn_separator: 'comma' | 'new_line' | '';
  conn_link: `scene_${string}` | '';
  link_type: 'text' | 'field';
  link_text: string;
  link_field: string;
  link_design_active: boolean;
  icon: {
    icon: string;
    align: 'left' | 'right';
  };
  img_gallery: string;
  width: {
    type: 'default' | 'custom';
    units: 'px' | 'pt';
    amount: string;
  };
  align: 'left' | 'center' | 'right';
  rules: TableViewDisplayRule[];

  // Record rules are only present with inline editing enabled
  edit_rules?: RecordRule[];

  // If we have an action column, this property does not arrive
  field?: {
    key: KnackFieldKey;
  };
  header: string;
  remote?: boolean;
  type: 'link' | 'field' | 'delete' | 'action_link' | 'scene_link';
  scene?: string | TableViewChildScenePayload;
  sortable?: boolean;

  // These properties are only present when field type is 'paragraph_text'
  truncate?: boolean;
  character_limit?: number;
  source?: {
    filters: KnackCriteria[];
  };
}

// Payload for creating a new child scene
export interface TableViewChildScenePayload {
  name: string;
  object: KnackObject['key'];
  parent: string;
  views: Array<DetailsView | FormView>;
}

export interface TableViewRecord {
  id?: string;
  htmlValue: HTMLElement | undefined;
  [key: KnackFieldKey]: any;
}

export interface TableViewActionRule {
  key?: string;
  criteria: KnackCriteria[];
  link_text: string;
  record_rules: RecordRule[];
  submit_rules: TableViewSubmitRule[];
}

export interface TableViewDisplayRule {
  key: string;
  actions: TableDisplayRuleAction[];
  criteria: KnackCriteria[];
}

export interface TableDisplayRuleAction {
  action: TableDisplayRuleActionType;
  value: string;
  color: string;
  bold: boolean;
  italic: boolean;
  strikethrough: boolean;
  icon: TableDisplayRuleIcon;
}

export type TableDisplayRuleIcon = {
  icon: string;
  align: 'left' | 'right';
  color: string;
};

export const TABLE_VIEW_DISPLAY_RULE_ACTION_TYPES = [
  'bg-color',
  'text-color',
  'text-style',
  'hide',
  'icon'
] as const;

export type TableDisplayRuleActionType = (typeof TABLE_VIEW_DISPLAY_RULE_ACTION_TYPES)[number];

export interface TableViewSubmitRule {
  key: string;
  action: 'message' | 'existing_page' | 'child_page' | 'url';
  criteria?: KnackCriteria[];
  is_default: boolean;
  reload_show: boolean;
  reload_auto?: boolean;
  message: string;
  existing_page?: string;
  scene?:
    | string
    | {
        name: string;
        object: KnackObject['key'];
        parent: string;
      };
  url?: string;
}
