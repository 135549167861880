import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useIMask } from 'react-imask';
import { Input } from '@knack/asterisk-react';

import { type NumberField } from '@/types/schema/fields';

type NumberInputProps = {
  name: string;
  id: string;
  format: NumberField['format'];
};

const numberDelimiterMapping = {
  comma: ',',
  period: '.',
  space: ' ',
  none: ''
} as const;

export function NumberInput({ name, id, format }: NumberInputProps) {
  const [t] = useTranslation();
  const {
    register,
    formState: { errors },
    setValue: setFormValue,
    getValues
  } = useFormContext();

  const decimalDelimiter = format?.mark_decimal;
  const thousandDelimiter = format?.mark_thousands || 'none';

  const formatDecimalDelimiter = () => {
    if (!decimalDelimiter) return '';
    if (decimalDelimiter === 'none') return '';

    const hasSameDelimiter = decimalDelimiter === thousandDelimiter;

    if (hasSameDelimiter) {
      return decimalDelimiter === 'comma' ? '.' : ',';
    }

    return numberDelimiterMapping[decimalDelimiter];
  };

  const { ref: inputRef, value: iMaskValue } = useIMask<HTMLInputElement>(
    {
      mask: Number,
      scale: decimalDelimiter !== 'none' ? Number(format?.precision) : 0,
      ...(thousandDelimiter !== 'none' && {
        thousandsSeparator: numberDelimiterMapping[thousandDelimiter]
      }),
      ...(decimalDelimiter !== 'none' && {
        radix: formatDecimalDelimiter()
      }),
      padFractionalZeros: true
    },
    {
      defaultValue: getValues(name),
      onAccept: (val: string, maskRef) => {
        const rawValue = maskRef.rawInputValue;
        setFormValue(name, rawValue);
      }
    }
  );

  const { ref: formRef, ...formRegister } = register(name);

  return (
    <Input
      {...formRegister}
      id={id}
      ref={inputRef}
      data-testid="number-input"
      className="text-sm [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
      placeholder={t('components.add_into_existing_table.enter_number')}
      intent={errors[name] ? 'destructive' : 'default'}
      value={iMaskValue}
      onChange={() => {}}
    />
  );
}
