import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Banner, Button, Dialog, Divider, Form } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackObject } from '@/types/schema/KnackObject';
import {
  type TableDisplayRuleAction,
  type TableViewDisplayRule
} from '@/types/schema/views/TableView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { CriteriaForm } from '@/components/CriteriaForm';
import { TableDisplayRuleFormActions } from '@/pages/pages/settings-panel/view-settings/table/data-display/display-rules/TableDisplayRuleFormActions';

function findDuplicateRuleActions(ruleActions: TableDisplayRuleAction[]) {
  const actionsSeen = new Set<string>();
  const duplicateRuleActions: TableDisplayRuleAction[] = [];

  ruleActions.forEach((item) => {
    const identifier = item.action;
    if (actionsSeen.has(identifier)) {
      duplicateRuleActions.push(item);
    } else {
      actionsSeen.add(identifier);
    }
  });

  return duplicateRuleActions;
}

interface TableDisplayRuleFormDialogProps {
  displayRule: TableViewDisplayRule;
  sourceObject: KnackObject;
  formIntent?: 'edit' | 'add';
  onRuleSave: (updatedDisplayRule: TableViewDisplayRule) => void;
  onOpenChange: (isOpen: boolean) => void;
}

export function TableDisplayRuleFormDialog({
  displayRule,
  sourceObject,
  formIntent = 'edit',
  onRuleSave,
  onOpenChange
}: TableDisplayRuleFormDialogProps) {
  const [t] = useTranslation();
  const availableViewFields = sourceObject.fields;

  const { validateCriteriaValues } = useCriteriaHelpers();

  const displayRuleFormSchema = z.custom<TableViewDisplayRule>().superRefine((data, context) => {
    const criteriaValueErrors = validateCriteriaValues(data.criteria, availableViewFields);

    if (criteriaValueErrors.length) {
      criteriaValueErrors.forEach((error) => {
        context.addIssue({
          path: error.path,
          message: error.message,
          code: 'custom'
        });
      });
    }

    // Loop through the rule actions and check if there are any duplicates
    const duplicateActions = findDuplicateRuleActions(data.actions as TableDisplayRuleAction[]);

    if (duplicateActions.length) {
      context.addIssue({
        path: ['actions'],
        message: t('components.display_rule_card.errors.duplicate_actions'),
        code: 'custom'
      });
    }
  });

  const form = useForm<TableViewDisplayRule>({
    resolver: zodResolver(displayRuleFormSchema),
    defaultValues: displayRule
  });

  const onSubmit = (data: TableViewDisplayRule) => {
    onRuleSave(data);
    onOpenChange(false);
  };

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <FormProvider {...form}>
          <form className="w-full" onSubmit={form.handleSubmit(onSubmit)}>
            <Dialog.MainContent>
              <Dialog.Header>
                <Dialog.Title>
                  {formIntent === 'edit'
                    ? t('components.display_rule_card.edit_modal.title')
                    : t('components.display_rule_card.add_modal.title')}
                </Dialog.Title>
                <Dialog.Description className="sr-only">
                  {formIntent === 'edit'
                    ? t('components.display_rule_card.edit_modal.description')
                    : t('components.display_rule_card.add_modal.description')}
                </Dialog.Description>
              </Dialog.Header>

              <div className="mt-6">
                <h3 className="mb-2 font-medium">{t('components.rules.when_extended')}</h3>
                {formIntent === 'add' && availableViewFields.length === 0 ? (
                  <Form.Section className="rounded-lg bg-subtle p-2">
                    <p className="text-subtle">
                      {t('components.display_rule_card.no_eligible_field_inputs')}
                    </p>
                  </Form.Section>
                ) : (
                  <div className="rounded-lg bg-subtle p-2">
                    <CriteriaForm
                      sourceObject={sourceObject}
                      criteriaType="display-rule"
                      shouldHaveContent
                    />
                  </div>
                )}
                <Divider className="my-4 *:bg-action" />
                <h3 className="mb-2 flex items-center font-medium">
                  <span className="mr-1">{t('components.rules.then_extended')}</span>
                </h3>
                {formIntent === 'add' && availableViewFields.length === 0 ? (
                  <Form.Section className="rounded-lg bg-subtle p-2">
                    <p className="text-subtle">
                      {t('components.display_rule_card.no_eligible_field_inputs')}
                    </p>
                  </Form.Section>
                ) : (
                  <TableDisplayRuleFormActions />
                )}
                {form.formState.errors.actions?.root && (
                  <Banner intent="warning" className="mt-2">
                    {form.formState.errors.actions?.root.message}
                  </Banner>
                )}
              </div>
            </Dialog.MainContent>

            <Dialog.Footer>
              <Dialog.Close asChild>
                <Button intent="minimal">{t('actions.cancel')}</Button>
              </Dialog.Close>
              <Button data-testid="add-rule-button" type="submit">
                {formIntent === 'edit'
                  ? t('components.display_rule_card.edit_modal.submit_button')
                  : t('components.display_rule_card.add_modal.submit_button')}
              </Button>
            </Dialog.Footer>
          </form>
        </FormProvider>
      </Dialog.Content>
    </Dialog>
  );
}
