import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  HiTableCells as DataIcon,
  HiDocument as PagesIcon,
  HiUserGroup as UsersIcon
} from 'react-icons/hi2';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { cn } from '@/utils/tailwind';
import type { InfoItemProps } from '@/pages/welcome/InfoItem';

export const welcomeTypes = {
  template: 'template',
  ai: 'ai'
} as const;

const ICON_CLASSES = 'min-h-6 min-w-6 fill-[url(#svg-gradient-2)]';

type WelcomeData = {
  data: Omit<InfoItemProps, 'animationDelay'>[];
  isLoading: boolean;
};

export function useWelcomeData(type: keyof typeof welcomeTypes | undefined): WelcomeData {
  const { data: app, isLoading: isAppLoading } = useApplicationQuery();
  const [t] = useTranslation();

  const data = useMemo<WelcomeData['data']>(() => {
    if (type === undefined) return [];

    if (type === 'template') {
      return [
        {
          icon: <DataIcon className={cn(ICON_CLASSES)} />,
          renderTitle: () => (
            <span className="font-medium text-emphasis">
              {t('components.welcome.template.data_title')}
            </span>
          ),
          renderDescription: (params) =>
            t('components.welcome.template.data_description', { tableName: params.get('table') })
        },
        {
          icon: <PagesIcon className={cn(ICON_CLASSES)} />,
          renderTitle: () => (
            <span className="font-medium text-emphasis">
              {t('components.welcome.template.pages_title')}
            </span>
          ),
          renderDescription: () => t('components.welcome.template.pages_description')
        },
        {
          icon: <UsersIcon className={cn(ICON_CLASSES)} />,
          renderTitle: () => (
            <span className="font-medium text-emphasis">
              {t('components.welcome.template.users_title')}
            </span>
          ),
          renderDescription: (params) =>
            t('components.welcome.template.users_description', {
              roleName: params.get('user-role')
            })
        }
      ];
    }

    if (!app) return [];

    const tables = app.objects.filter((object) => object.type === 'StandardObject');

    const firstTableName = tables[0]?.name;
    const secondTableName = tables[1]?.name;

    let dataDescription = '';

    if (tables.length === 1) {
      dataDescription = firstTableName;
    } else if (tables.length === 2) {
      dataDescription = t('components.welcome.ai.data_two_tables', {
        firstTable: firstTableName,
        secondTable: secondTableName
      });
    } else if (tables.length > 2) {
      dataDescription = t('components.welcome.ai.data_more_tables', {
        firstTable: firstTableName,
        secondTable: secondTableName
      });
    }

    return [
      {
        icon: <DataIcon className={cn(ICON_CLASSES, 'min-h-8 min-w-8')} />,
        renderTitle: () => (
          <Trans
            i18nKey="components.welcome.ai.data_title"
            values={{
              tableCount: tables.length,
              description: dataDescription
            }}
          >
            <span className="font-medium text-emphasis" />
          </Trans>
        )
      },
      {
        icon: <PagesIcon className={cn(ICON_CLASSES, 'min-h-8 min-w-8')} />,
        renderTitle: () => (
          <Trans i18nKey="components.welcome.ai.pages_title">
            <span className="font-medium text-emphasis" />
          </Trans>
        )
      },
      {
        icon: <UsersIcon className={cn(ICON_CLASSES, 'min-h-8 min-w-8')} />,
        renderTitle: () => (
          <Trans
            i18nKey="components.welcome.ai.roles_title"
            values={{
              roleCount: app.objects.filter(
                (object) => object.type === 'UserObject' && object.profile_key !== 'all_users'
              ).length
            }}
          >
            <span className="font-medium text-emphasis" />
          </Trans>
        )
      }
    ];
  }, [app, t, type]);

  return {
    data,
    isLoading: isAppLoading
  };
}
