import { t } from 'i18next';
import { nanoid } from 'nanoid';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import {
  VIEW_COLUMN_ID_PREFIX,
  VIEW_GROUP_ID_PREFIX,
  type BuilderViewSourceSchema
} from '@/types/schema/BuilderView';
import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  DETAILS_VIEW_DIVIDER_INPUT_ID_PREFIX,
  DETAILS_VIEW_LINK_INPUT_ID_PREFIX,
  DETAILS_VIEW_RICH_TEXT_INPUT_ID_PREFIX,
  type DetailsView,
  type DetailsViewChildScenePayload,
  type DetailsViewInput
} from '@/types/schema/views/DetailsView';
import { type ViewSource } from '@/pages/pages/page-editor/add-view/helpers/useViewSources';
import { generateNewViewKey } from './generateNewViewKey';

const DETAILS_MAX_INPUT_FIELD_NUMBER = 7;

export const baseDetailsViewSchema: Partial<DetailsView> = {
  type: 'details',
  description: '',
  groups: [],
  inputs: [],
  links: [],
  label_format: 'left',
  layout: 'full',
  hide_fields: false,
  rules: {
    fields: []
  }
};

export const baseDetailsViewInputSchema: Partial<DetailsViewInput> = {
  label: '',
  conn_separator: '',
  conn_link: '',
  link_type: 'text',
  link_text: '',
  link_field: '',
  link_design_active: false,
  icon: {
    icon: '',
    align: 'left'
  },
  img_gallery: '',
  show_map: false,
  map_width: 400,
  map_height: 300,
  value: '',
  copy: '',
  format: {
    label_custom: false,
    label_format: 'left',
    styles: []
  }
};

export function generateDetailsViewDividerInput() {
  return {
    ...baseDetailsViewInputSchema,
    id: `${DETAILS_VIEW_DIVIDER_INPUT_ID_PREFIX}${nanoid(10)}`,
    type: 'divider'
  } satisfies Partial<DetailsViewInput> as DetailsViewInput;
}

export function generateDetailsViewRichTextInput() {
  return {
    ...baseDetailsViewInputSchema,
    id: `${DETAILS_VIEW_RICH_TEXT_INPUT_ID_PREFIX}${nanoid(10)}`,
    type: 'special_title'
  } as DetailsViewInput;
}

export function generateDetailsViewDeleteRecordInput() {
  return {
    ...baseDetailsViewInputSchema,
    id: `${DETAILS_VIEW_LINK_INPUT_ID_PREFIX}${nanoid(10)}`,
    type: 'delete',
    link_text: t('actions.delete')
  } satisfies Partial<DetailsViewInput> as DetailsViewInput;
}

export function generateDetailsViewEditRecordInput(newChildPage: DetailsViewChildScenePayload) {
  return {
    ...baseDetailsViewInputSchema,
    id: `${DETAILS_VIEW_LINK_INPUT_ID_PREFIX}${nanoid(10)}`,
    type: 'scene_link',
    link_text: t('actions.edit'),
    scene: newChildPage
  } satisfies Partial<DetailsViewInput> as DetailsViewInput;
}

export function generateDetailsViewRecordDetailsInput(newChildPage: DetailsViewChildScenePayload) {
  return {
    ...baseDetailsViewInputSchema,
    id: `${DETAILS_VIEW_LINK_INPUT_ID_PREFIX}${nanoid(10)}`,
    type: 'scene_link',
    link_text: t('actions.view'),
    scene: newChildPage
  } satisfies Partial<DetailsViewInput> as DetailsViewInput;
}

export function generateDetailsViewLinkInput(selectedPage: BuilderPage) {
  return {
    ...baseDetailsViewInputSchema,
    id: `${DETAILS_VIEW_LINK_INPUT_ID_PREFIX}${nanoid(10)}`,
    type: 'scene_link',
    link_text: selectedPage.name,
    scene: selectedPage.slug
  } satisfies Partial<DetailsViewInput> as DetailsViewInput;
}

export function generateDetailsViewFieldInput(
  field: KnackField,
  connectionFieldKey?: KnackFieldKey
) {
  return {
    ...baseDetailsViewInputSchema,
    id: `${field.key}_${nanoid(10)}`,
    type: 'field',
    key: field.key,
    name: field.name,
    ...(connectionFieldKey && {
      connection: {
        key: connectionFieldKey
      }
    })
  } satisfies Partial<DetailsViewInput> as DetailsViewInput;
}

export function generateDetailsViewInputs({
  viewSourceObject,
  ignoreFields = []
}: {
  viewSourceObject: KnackObject;
  ignoreFields?: KnackFieldKey[];
}) {
  const detailsInputs: DetailsViewInput[] = [];

  let addedInputsCount = 0;

  viewSourceObject.fields.forEach((field) => {
    if (ignoreFields.includes(field.key)) {
      return;
    }

    if (addedInputsCount >= DETAILS_MAX_INPUT_FIELD_NUMBER) {
      return;
    }

    // Skip password fields
    if (field.type === 'password') {
      return;
    }

    // Skip user roles and multiple choice fields if the view source if the field is a user field
    if ((field.type === 'user_roles' || field.type === 'multiple_choice') && field.user) {
      return;
    }

    detailsInputs.push(generateDetailsViewFieldInput(field));

    addedInputsCount += 1;
  });

  return detailsInputs;
}

export function getDetailsViewSchemaFromPartialView(
  partialFormView: Partial<DetailsView>,
  sourceObject: KnackObject
) {
  const detailsViewSchema: Partial<DetailsView> = {
    ...baseDetailsViewSchema,
    ...partialFormView,
    columns: partialFormView.source
      ? [
          {
            id: `${VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
            width: 100,
            groups: [
              {
                id: `${VIEW_GROUP_ID_PREFIX}${nanoid(10)}`,
                columns: [
                  generateDetailsViewInputs({
                    viewSourceObject: sourceObject
                  })
                ]
              }
            ]
          }
        ]
      : []
  };

  return detailsViewSchema as DetailsView;
}

export function getDetailsViewSchema(
  viewSource: ViewSource,
  viewSourceSchema: BuilderViewSourceSchema
) {
  const objectName =
    viewSource.recordDisplayQuantity === 'many'
      ? viewSource.object.inflections.plural
      : viewSource.object.inflections.singular;
  const viewTitle = t('views.new_view_defaults.details.name', {
    objectName
  });

  const detailsViewSchema: Partial<DetailsView> = {
    ...baseDetailsViewSchema,
    key: generateNewViewKey(),
    name: viewTitle,
    title: viewTitle,
    source: viewSourceSchema,
    columns: [
      {
        id: `${VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
        groups: [
          {
            id: `${VIEW_GROUP_ID_PREFIX}${nanoid(10)}`,
            columns: [
              generateDetailsViewInputs({
                viewSourceObject: viewSource.object
              })
            ]
          }
        ],
        width: 100
      }
    ]
  };

  return detailsViewSchema;
}
