import { useTranslation } from 'react-i18next';
import { Input, Label, RichTextEditor } from '@knack/asterisk-react';

import { type BuilderView } from '@/types/schema/BuilderView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

interface ViewTitleDescriptionSettingsProps {
  className?: string;
  shouldShowOnlyLabel?: boolean;
  isDefaultOpen?: boolean;
}

function ViewLabelInput() {
  const [t] = useTranslation();

  const { view, viewInitialState } = useActiveViewContext<BuilderView>();
  const updateViewSchema = useUpdateView<BuilderView>();

  return (
    <div>
      <span className="mb-2 block font-medium">
        {t('pages.element_settings.general.view_label')}
      </span>
      <Input
        intent={!view.name ? 'destructive' : undefined}
        value={view.name || ''}
        onChange={(e) => {
          updateViewSchema({
            name: e.target.value
          });
        }}
        onBlur={(e) => {
          const value = e.target.value.trim();

          // We don't ever want the value to be empty in the schema, so if it's still empty after a blur, we'll reset it to the initial value
          if (!value && viewInitialState) {
            updateViewSchema({
              name: viewInitialState.name
            });
          }
        }}
      />
      {!view.name && (
        <p className="mt-2 text-xs text-destructive">
          {t('pages.element_settings.general.view_label_required')}
        </p>
      )}
      <p className="mt-2 text-xs text-subtle">{t('pages.element_settings.general.label_hint')}</p>
    </div>
  );
}

export function ViewTitleDescriptionSettings({
  className = '',
  shouldShowOnlyLabel = false,
  isDefaultOpen = true
}: ViewTitleDescriptionSettingsProps) {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<BuilderView>();
  const updateViewSchema = useUpdateView<BuilderView>();

  if (shouldShowOnlyLabel) {
    return <ViewLabelInput />;
  }

  return (
    <BuilderAccordion.Item
      isDefaultOpen={isDefaultOpen}
      label={t('pages.element_settings.general.title_and_description')}
      className={className}
    >
      <div className="mb-4">
        <Label className="mb-2 block font-medium" htmlFor={`${view.key}-view-title`}>
          {t('pages.element_settings.general.view_title')}
        </Label>
        <Input
          value={view.title ?? ''}
          onChange={(e) => {
            updateViewSchema({
              title: e.target.value
            });
          }}
        />
        <p className="mt-2 text-xs text-subtle">{t('pages.element_settings.general.title_hint')}</p>
      </div>
      <div className="mb-4">
        <span className="mb-2 block font-medium">
          {t('pages.element_settings.general.view_description')}
        </span>
        <RichTextEditor
          onUpdate={({ editor }) => {
            updateViewSchema({
              description: editor.getText() ? editor.getHTML() : ''
            });
          }}
          content={view.description || ''}
        />
        <p className="mt-2 text-xs text-subtle">
          {t('pages.element_settings.general.description_hint')}
        </p>
      </div>
      <ViewLabelInput />
    </BuilderAccordion.Item>
  );
}
