import { Controller, type FieldPath } from 'react-hook-form';
import {
  MdFormatBold as BoldIcon,
  MdFormatItalic as ItalicIcon,
  MdOutlineStrikethroughS as StrikeThroughIcon
} from 'react-icons/md';
import { Button } from '@knack/asterisk-react';

import {
  type TableDisplayRuleActionType,
  type TableViewDisplayRule
} from '@/types/schema/views/TableView';
import { cn } from '@/utils/tailwind';
import { DisplayRuleColorPicker } from '@/pages/pages/settings-panel/view-settings/common/display-rules/DisplayRuleColorPicker';

interface TextStyleActionSelectProps {
  formName: FieldPath<TableViewDisplayRule>;
}

const DEFAULT_COLOR_PICKER_COLOR = '#000000';

interface TableDisplayRuleFormActionPickerProps {
  action: TableDisplayRuleActionType;
  formName: FieldPath<TableViewDisplayRule>;
}

function TextStyleActionSelect({ formName }: TextStyleActionSelectProps) {
  return (
    <div className="flex gap-2">
      <Controller
        name={`${formName}.bold`}
        render={({ field: { onChange, value: bold } }) => (
          <Button
            data-testid="text-style-bold-button"
            intent="secondary"
            className={cn({ 'bg-action': bold })}
            onClick={() => onChange(!bold)}
            size="sm"
          >
            <BoldIcon size={16} />
          </Button>
        )}
      />
      <Controller
        name={`${formName}.italic`}
        render={({ field: { onChange, value: italic } }) => (
          <Button
            data-testid="text-style-italic-button"
            intent="secondary"
            onClick={() => onChange(!italic)}
            size="sm"
            className={cn({ 'bg-action': italic })}
          >
            <ItalicIcon size={16} />
          </Button>
        )}
      />
      <Controller
        name={`${formName}.strikethrough`}
        render={({ field: { onChange, value: strikethrough } }) => (
          <Button
            data-testid="text-style-strikethrough-button"
            intent="secondary"
            onClick={() => onChange(!strikethrough)}
            size="sm"
            className={cn({ 'bg-action': strikethrough })}
          >
            <StrikeThroughIcon size={16} />
          </Button>
        )}
      />
    </div>
  );
}

export function TableDisplayRuleFormActionPicker({
  action,
  formName
}: TableDisplayRuleFormActionPickerProps) {
  if (action === 'text-style') {
    return <TextStyleActionSelect formName={formName} />;
  }

  if (action === 'text-color') {
    return (
      <Controller
        name={`${formName}.color`}
        render={({ field: { value, onChange } }) => {
          const defaultValue = value || DEFAULT_COLOR_PICKER_COLOR;

          return <DisplayRuleColorPicker value={defaultValue} onChange={onChange} />;
        }}
      />
    );
  }

  if (action === 'bg-color') {
    return (
      <Controller
        name={`${formName}.value`}
        render={({ field: { value, onChange } }) => {
          const defaultValue = value || DEFAULT_COLOR_PICKER_COLOR;

          return <DisplayRuleColorPicker value={defaultValue} onChange={onChange} />;
        }}
      />
    );
  }

  return null;
}
