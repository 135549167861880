import { useState } from 'react';
import { HiTrash as DeleteIcon, HiPencil as PencilIcon } from 'react-icons/hi2';
import { Button, Card } from '@knack/asterisk-react';
import { t } from 'i18next';
import snakeCase from 'lodash.snakecase';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type MapPinColor } from '@/types/schema/views/MapView';
import { InlineKnackCriteriaValue } from '@/components/InlineKnackCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';
import { PinRuleForm } from '@/pages/pages/settings-panel/view-settings/map/pin-rules/PinRuleForm';

type PinRuleCardProps = {
  sourceObject: KnackObject;
  pinRule: MapPinColor;
  pinRuleIndex: number;
  onFilterSave: (filter: MapPinColor, filterIndex?: number) => void;
  onFilterDelete: (filterIndex: number) => void;
};

export function PinRuleCard({
  sourceObject,
  pinRule,
  onFilterSave,
  onFilterDelete,
  pinRuleIndex
}: PinRuleCardProps) {
  const [isEditing, setIsEditing] = useState(false);

  const selectedField = sourceObject.fields.find((field) => field.key === pinRule.field);

  if (!selectedField) {
    return null;
  }

  if (onFilterSave && isEditing) {
    return (
      <PinRuleForm
        pinRule={pinRule}
        sourceObject={sourceObject}
        onCancel={() => {
          setIsEditing(false);
        }}
        onSave={(filterData) => {
          onFilterSave(filterData, pinRuleIndex);
          setIsEditing(false);
        }}
      />
    );
  }

  return (
    <Card className="group relative bg-subtle p-2 shadow-none sm:p-2">
      <div>
        <div className="mr-1 inline-flex">
          {t('pages.element_settings.map.styling_settings.where')}
        </div>
        <InlineKnackField
          fieldType={selectedField.type}
          fieldName={selectedField.name}
          className="bg-action"
        />{' '}
        {t(`operators.${snakeCase(pinRule.operator)}`)}{' '}
        <InlineKnackCriteriaValue
          criteria={pinRule}
          criteriaField={selectedField}
          className="bg-action"
        />
      </div>
      <div className="mt-2">
        <div>{t('pages.element_settings.map.styling_settings.then_set_pin_color')}</div>
        <div className="mt-1 inline-flex items-center gap-1 rounded-md border-subtle bg-action p-1 text-emphasis">
          <div className="h-4 w-4 rounded-sm" style={{ backgroundColor: pinRule.color }} />
          <div className="text-sm">{pinRule.color}</div>
        </div>
      </div>

      <div className="absolute bottom-0 right-2 top-2 hidden gap-2 group-hover:flex">
        <Button
          intent="minimal"
          aria-label={t('actions.edit')}
          size="xs"
          className="text-subtle"
          onClick={() => setIsEditing(true)}
        >
          <PencilIcon size={14} />
        </Button>
        <Button
          intent="minimal"
          aria-label={t('actions.delete')}
          size="xs"
          className="text-subtle"
          onClick={() => {
            onFilterDelete(pinRuleIndex);
          }}
        >
          <DeleteIcon size={14} />
        </Button>
      </div>
    </Card>
  );
}
