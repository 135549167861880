import { queryOptions, useQuery } from '@tanstack/react-query';

import { type KnackObject } from '@/types/schema/KnackObject';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useApplication } from '@/hooks/useApplication';
import { axiosInstance } from '@/utils/axiosConfig';
import { sanitizeHTMLTags } from '@/utils/formatters';

interface ConnectionRecordResponse {
  total_pages: number;
  current_page: number;
  total_records: number;
  records: ConnectionRecord[];
}

export interface ConnectionRecord {
  id: string;
  identifier: string | number;
}

export async function getConnectionRecords(
  applicationId: string,
  objectKey: KnackObject['key'],
  rowsPerPage: number | 'all_records' = 'all_records'
) {
  const { data: records } = await axiosInstance.get<ConnectionRecordResponse>(
    `/v1/applications/${applicationId}/connections/${objectKey}?rows_per_page=${rowsPerPage}`,
    { withCredentials: true }
  );

  const sanitizedRecords =
    records?.records?.map((item) => ({
      ...item,
      identifier: sanitizeHTMLTags(String(item.identifier))
    })) || [];

  return {
    ...records,
    records: sanitizedRecords
  };
}

export function connectionRecordsQuery(pageKey: string, objectKey: KnackObject['key']) {
  return queryOptions({
    queryKey: [queryKeys.connectionRecord, objectKey],
    queryFn: async () => getConnectionRecords(pageKey, objectKey)
  });
}

export function useGetConnectionRecords(objectKey: KnackObject['key'] | '') {
  const application = useApplication();
  if (!application) throw new Error('Application not found');
  if (objectKey === '') throw new Error('Object key not found');

  return useQuery(connectionRecordsQuery(application.id, objectKey));
}
