import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';
import { initAxios } from '@/utils/axiosConfig';
import { AppNotFound } from '@/components/errors/AppNotFound';
import { FullPageSpinner } from '@/components/FullPageSpinner';
import { InactivityTimeout } from '@/components/InactivityTimeout';
import { JobNotification } from '@/components/jobs/JobNotification';
import { ChatBotProvider } from '@/ChatBotProvider';
import { FlagsmithSetup } from '@/FlagsmithSetup';
import { ThirdPartyIntegrations } from '@/ThirdPartyIntegrations';

initAxios();

export function App({ children }: { children?: React.ReactNode }) {
  const {
    data: session,
    isPlaceholderData: isSessionPlaceholderData,
    isLoading: isSessionLoading
  } = useSessionQuery();

  const shouldEnableAppInitQueries = !!session?.account.id || !!session?.user.id;
  const { data: application, isLoading: isApplicationLoading } = useApplicationQuery(
    shouldEnableAppInitQueries
  );

  const isAppLoading = isSessionLoading || isSessionPlaceholderData || isApplicationLoading;
  if (isAppLoading) {
    return <FullPageSpinner />;
  }

  const isAuthenticated = !!session?.user.id;
  if (!isAuthenticated) {
    window.location.replace(`${import.meta.env.PUBLIC_DASHBOARD_URL}/sign-in`);
    return null;
  }

  if (!application) {
    return <AppNotFound />;
  }

  return (
    <ThirdPartyIntegrations session={session} application={application}>
      <FlagsmithSetup accountId={application.account.id}>
        <ChatBotProvider>{children}</ChatBotProvider>
        <JobNotification />
        <InactivityTimeout />
      </FlagsmithSetup>
    </ThirdPartyIntegrations>
  );
}
