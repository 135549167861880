import { type KnackObject } from '@/types/schema/KnackObject';
import { type FormView, type FormViewSubmitRule } from '@/types/schema/views/FormView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useSubmitRuleHelpers } from '@/hooks/helpers/useSubmitRuleHelpers';

export function useFormSubmitRulesHelpers() {
  const { getDefaultCriteria } = useCriteriaHelpers();
  const { getDefaultSubmitRule } = useSubmitRuleHelpers();

  const getDefaultFormSubmitRule = (sourceObject: KnackObject, formAction: FormView['action']) => {
    const defaultCriteria = getDefaultCriteria(
      sourceObject,
      'submit-rule',
      formAction === 'update'
    );

    const defaultFormSubmitRule: FormViewSubmitRule = {
      ...getDefaultSubmitRule(),
      criteria: defaultCriteria ? [defaultCriteria] : []
    };

    return defaultFormSubmitRule;
  };

  return {
    getDefaultFormSubmitRule
  };
}
