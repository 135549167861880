import { useTranslation } from 'react-i18next';
import { BsStars as AiStarsIcon } from 'react-icons/bs';

export function UpgradeButton() {
  const [t] = useTranslation();
  return (
    <a
      href={`${import.meta.env.PUBLIC_DASHBOARD_URL}/settings/billing/plans`}
      target="_blank"
      rel="noreferrer"
    >
      <div className="shrink-0 rounded-2xl bg-action-gradient px-[1.5px] py-px">
        <div className="flex h-6 items-center gap-1 rounded-2xl bg-base px-2 font-medium">
          <div>
            <svg width="0" height="0">
              <linearGradient id="bg-gradient" x1="00%" y1="100%" x2="0%" y2="0%">
                <stop style={{ stopColor: 'rgb(var(--brand-600))' }} offset="0%" />
                <stop style={{ stopColor: 'rgb(var(--pink-400))' }} offset="100%" />
              </linearGradient>
            </svg>
            <AiStarsIcon
              className="flex-none rotate-90"
              size={20}
              style={{ fill: 'url(#bg-gradient)' }}
            />
          </div>
          <span
            style={{
              background: 'linear-gradient(to right, #86307b, #e61875)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            {t('actions.upgrade')}
          </span>
        </div>
      </div>
    </a>
  );
}
