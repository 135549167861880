import { type ComponentProps } from 'react';
import { RichTextRenderer } from '@knack/asterisk-react';
import truncate from 'lodash/truncate';

import { type BooleanField } from '@/types/schema/fields';
import { type KnackField } from '@/types/schema/KnackField';
import { useAssetInfoQuery } from '@/hooks/api/queries/useAssetInfoQuery';
import { cn } from '@/utils/tailwind';
import { OPTIONS_BY_FORMAT } from '@/components/data-table/display/fields/boolean/constants';
import { type Field } from '@/components/data-table/display/fields/Field';

interface InlineKnackRecordValueProps extends ComponentProps<'span'> {
  value: Field['rawValue']; // TODO: Refactor the Field type and extract it from Data Table. FE-3657
  field: KnackField;
}

const MAX_CHARACTERS_IN_RICH_TEXT = 50;
const MAX_CHARACTERS_IN_FILE_NAME = 20;

export function InlineKnackRecordValue({
  value,
  className,
  field,
  ...props
}: InlineKnackRecordValueProps) {
  const commonClassNames = cn(
    'inline-flex rounded-md bg-subtle p-1 align-middle leading-none items-center text-emphasis',
    className
  );

  // If the field is an image or file, we need to fetch the asset info
  const { data: assetInfo } = useAssetInfoQuery({
    assetId: value as string,
    enabled: (field.type === 'image' || field.type === 'file') && typeof value === 'string'
  });

  // Create a partial Field object with the rawValue for type narrowing
  const formattedValue = {
    type: field.type,
    rawValue: value
  } as Partial<Field>;

  if (formattedValue.type === 'rich_text' && formattedValue.rawValue) {
    return (
      <RichTextRenderer
        className={cn('rounded-md bg-subtle p-1 [&_p]:inline', className)}
        dangerouslySetInnerHTML={{
          __html: truncate(formattedValue.rawValue, {
            length: MAX_CHARACTERS_IN_RICH_TEXT
          })
        }}
        {...props}
      />
    );
  }

  const getFormattedBooleanDefaultValue = (val: boolean, fieldFormat: BooleanField['format']) => {
    if (!fieldFormat) {
      return val ? OPTIONS_BY_FORMAT.yes_no[0] : OPTIONS_BY_FORMAT.yes_no[1];
    }
    const options = Object.keys(OPTIONS_BY_FORMAT[fieldFormat.format]);

    return val ? options[0] : options[1];
  };

  const renderValueBasedOnFieldType = () => {
    if (formattedValue.rawValue) {
      switch (formattedValue.type) {
        case 'boolean': {
          if (field.type === 'boolean') {
            return getFormattedBooleanDefaultValue(formattedValue.rawValue, field.format);
          }
          break;
        }
        case 'multiple_choice': {
          if (Array.isArray(formattedValue.rawValue)) {
            return formattedValue.rawValue.join(', ');
          }
          return formattedValue.rawValue;
        }
        case 'date_time': {
          return formattedValue.rawValue.date;
        }
        case 'timer': {
          const time = formattedValue.rawValue.times[0];
          return `${time.from.date} ${time.from.rawTime}${time.from.am_pm} to ${time.to.date} ${time.to.rawTime}${time.to.am_pm}`;
        }
        case 'email': {
          return formattedValue.rawValue.email;
        }
        case 'link': {
          return formattedValue.rawValue.url;
        }
        case 'phone': {
          return formattedValue.rawValue;
        }
        case 'address': {
          return formattedValue.rawValue.full || `${formattedValue.rawValue.street}...`;
        }
        case 'connection': {
          if (formattedValue.rawValue.length > 0) {
            const values = formattedValue.rawValue.map((val) => val.identifier);
            return values.join(', ');
          }
          return '-';
        }
        case 'name': {
          if (field.type === 'name') {
            if (field.format?.format === 'First Last') {
              return `${formattedValue.rawValue.first} ${formattedValue.rawValue.last}`;
            }
            if (field.format?.format === 'Last, First') {
              return `${formattedValue.rawValue.last}, ${formattedValue.rawValue.first}`;
            }
            if (field.format?.format === 'Title First Last') {
              return `${formattedValue.rawValue.title} ${formattedValue.rawValue.first} ${formattedValue.rawValue.last}`;
            }
            if (field.format?.format === 'First Middle Last') {
              return `${formattedValue.rawValue.first} ${formattedValue.rawValue.middle} ${formattedValue.rawValue.last}`;
            }
            return formattedValue.rawValue.full;
          }
          break;
        }
        case 'image':
        case 'file': {
          if (assetInfo) {
            const [fileName, fileExtension] = assetInfo.filename.split('.');

            return `${truncate(fileName, { length: MAX_CHARACTERS_IN_FILE_NAME, omission: '..' })}.${fileExtension}`;
          }
          break;
        }
        default: {
          return value as string;
        }
      }
    }
    return value as string;
  };

  return (
    <span className={commonClassNames} {...props}>
      {renderValueBasedOnFieldType()}
    </span>
  );
}
