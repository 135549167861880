import { useEffect } from 'react';
import { Chip } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplication } from '@/hooks/useApplication';
import { sanitizeHTMLTags } from '@/utils/formatters';
import { ConnectionsEdit } from '@/components/data-table/display/fields/connection/ConnectionsEdit';
import { getConnectionRecords } from '@/components/data-table/display/fields/connection/useGetConnectionQuery';
import { type ConnectionField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function ConnectionValues(props: {
  rawValue: ConnectionField['rawValue'];
  onDeleteOption?: (option: { id: string; identifier: string }) => void;
}) {
  const { rawValue, onDeleteOption } = props;

  return (
    <div className="size-full overflow-auto">
      <div className="flex flex-wrap">
        {rawValue?.length > 0 &&
          rawValue?.map((item) => (
            <Chip
              text={sanitizeHTMLTags(item.identifier)}
              key={sanitizeHTMLTags(item.identifier)}
              className="m-1 mb-0"
              {...(onDeleteOption && {
                onClose: (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDeleteOption(item);
                }
              })}
            />
          ))}
      </div>
    </div>
  );
}

export function ConnectionRender(props: FieldRenderProps<ConnectionField>) {
  const { rawValue, isFloating, rowId, fieldId, type } = props;

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);
  const { relationship, format } = currentField;

  const draftRows = useDataTableStore().use.draftRows();
  const { saveCell } = useDataTableStore().use.actions();
  const application = useApplication();

  const isNewRow = rowId?.startsWith('new_row');

  useEffect(() => {
    const hasDefaultValue = format?.conn_default === 'first';
    if (!application || !relationship) return;

    async function setValueToFirstItem(appId: string, objectKey: KnackObject['key']) {
      const result = await getConnectionRecords(appId, objectKey, 1);
      if (result.records.length === 0) return;

      const firstItem = result.records[0];
      const newRawValue = [
        {
          id: firstItem?.id || '',
          identifier: firstItem?.identifier.toString() || ''
        }
      ];
      void saveCell(rowId, fieldId, newRawValue, {
        value: '',
        rawValue: newRawValue
      });
    }

    if (
      isNewRow &&
      hasDefaultValue &&
      !rawValue?.length &&
      !draftRows[rowId][fieldId] &&
      relationship.object
    ) {
      void setValueToFirstItem(application.id, relationship.object);
    }
  }, [
    application,
    draftRows,
    fieldId,
    format?.conn_default,
    isNewRow,
    rawValue?.length,
    relationship,
    rowId,
    saveCell
  ]);

  if (isFloating) {
    return <ConnectionsEdit {...props} />;
  }

  return <ConnectionValues rawValue={rawValue} />;
}
