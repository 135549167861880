import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select, Switch } from '@knack/asterisk-react';
import { z } from 'zod';

import { ADDRESS_INPUT_OPTIONS, FORMAT_OPTIONS, type AddressField } from '@/types/schema/fields';
import { useSession } from '@/hooks/useSession';
import { UpgradeButton } from '@/components/UpgradeButton';

type AddressFormSettingsProps = {
  field: AddressField;
};

export const addressSchema = z.object({
  format: z.object({
    input: z.enum(ADDRESS_INPUT_OPTIONS),
    format: z.enum(FORMAT_OPTIONS),
    enable_geocoding: z.boolean().default(false),
    enable_address_autocomplete: z.boolean().default(false)
  })
});

export function AddressFormSettings({ field }: AddressFormSettingsProps) {
  const [t] = useTranslation();
  const sessionData = useSession();

  const { getValues } = useFormContext();

  const inputValue = getValues('format.input') || ADDRESS_INPUT_OPTIONS[0];
  const isAddressInputOptionSelected = inputValue?.includes('address');
  const isStarterPlan = sessionData.account.productPlan.id.startsWith('starter') ?? false;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <Label htmlFor="address-input-select" className="font-medium">
          {t('components.data_table.attributes.field_settings.address.input_label')}
        </Label>
        <Controller
          name="format.input"
          defaultValue={field?.format?.input || ADDRESS_INPUT_OPTIONS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={currentField.onChange}
              defaultValue={currentField.value || ADDRESS_INPUT_OPTIONS[0]}
            >
              <Select.Trigger
                id="address-input-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="address-form-settings-input-select"
                {...currentField}
              />
              <Select.Content>
                {ADDRESS_INPUT_OPTIONS.map((option) => (
                  <Select.Item
                    key={option}
                    value={option}
                    data-testid={`address-settings-input-value-${option}`}
                  >
                    <div className="flex items-center gap-1">
                      {t(
                        `components.data_table.attributes.field_settings.address.input_options.${option}`
                      )}
                    </div>
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>

      <div className="flex flex-col gap-4">
        {isStarterPlan && (
          <div className="flex">
            <UpgradeButton />
          </div>
        )}

        <div className="flex items-center">
          <Controller
            name="format.enable_geocoding"
            defaultValue={field?.format?.enable_geocoding || false}
            render={({ field: { onChange, value } }) => (
              <Switch
                disabled={isStarterPlan}
                id="enable-geocoding-switch"
                className="mr-2"
                data-testid="enable_geocoding-value-switch"
                checked={value}
                onCheckedChange={onChange}
              />
            )}
          />
          <Label htmlFor="enable-geocoding-switch" className="font-medium">
            {t('components.data_table.attributes.field_settings.address.enable_geocoding')}
          </Label>
        </div>

        {isAddressInputOptionSelected && (
          <div className="flex items-center">
            <Controller
              name="format.enable_address_autocomplete"
              defaultValue={field?.format?.enable_address_autocomplete || false}
              render={({ field: { onChange, value } }) => (
                <Switch
                  disabled={isStarterPlan}
                  id="enable_address_autocomplete-switch"
                  className="mr-2"
                  data-testid="enable_address_autocomplete-value-switch"
                  checked={value}
                  onCheckedChange={onChange}
                />
              )}
            />
            <Label htmlFor="enable_address_autocomplete-switch" className="font-medium">
              {t(
                'components.data_table.attributes.field_settings.address.enable_address_autocomplete'
              )}
            </Label>
          </div>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <Label htmlFor="address-format-select" className="font-medium">
          {t('components.data_table.attributes.field_settings.address.format_label')}
        </Label>
        <Controller
          name="format.format"
          defaultValue={field?.format?.format || FORMAT_OPTIONS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={currentField.onChange}
              defaultValue={currentField.value || FORMAT_OPTIONS[0]}
            >
              <Select.Trigger
                id="address-format-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="address-form-settings-format-select"
                {...currentField}
              />
              <Select.Content>
                {FORMAT_OPTIONS.map((option) => (
                  <Select.Item
                    key={option}
                    value={option}
                    data-testid={`address-settings-format-value-${option}`}
                  >
                    <div className="flex items-center gap-1">
                      {t(
                        `components.data_table.attributes.field_settings.address.format_options.${option}`
                      )}
                    </div>
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>
    </div>
  );
}
