import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon, HiBolt as TasksIcon } from 'react-icons/hi2';
import { DndContext, DragOverlay, type DragEndEvent, type DragStartEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, useToast } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type KnackTask } from '@/types/schema/tasks/KnackTask';
import { useTaskMutation } from '@/hooks/api/mutations/useTaskMutation';
import { useDndUtils } from '@/hooks/useDndUtils';
import { EmptyState } from '@/components/EmptyState';
import { SidePanel } from '@/components/SidePanel';
import { TaskCardWrapper } from '@/pages/tables/toolkit-sidebar/tasks/TaskCardWrapper';
import { TaskForm } from './TaskForm';

type TasksPanelProps = {
  table: KnackObject;
  tasks: KnackTask[];
};

export function TasksPanel({ table, tasks }: TasksPanelProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const { addMutation, sortMutation } = useTaskMutation();

  const [tasksList, setTasksList] = useState(tasks);
  const [beingDraggedTaskKey, setBeingDraggedTaskKey] = useState<string | null>(null);

  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();

  const [shouldRenderAddTaskForm, setShouldRenderAddTaskForm] = useState(false);

  const handleDragStart = (event: DragStartEvent) => {
    setBeingDraggedTaskKey(event.active.id.toString());
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = tasksList.findIndex((v) => v.key === active.id) ?? -1;
      const newIndex = tasksList.findIndex((v) => v.key === over.id) ?? -1;

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      const updatedTasksList = arrayMove(tasksList, oldIndex, newIndex);

      setTasksList(updatedTasksList);

      sortMutation.mutate(
        {
          tableKey: table.key,
          taskOrder: updatedTasksList.map((task: KnackTask) => task.key)
        },
        {
          onError: () => {
            presentToast({
              title: t('components.data_table.right_sidebar.tasks.errors.sort_error')
            });
          }
        }
      );

      setBeingDraggedTaskKey(null);
    }
  };

  const onTaskSave = (taskData: KnackTask) => {
    addMutation.mutate(
      {
        tableKey: table.key,
        taskData
      },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.data_table.right_sidebar.tasks.task_save_success')
          });
          setShouldRenderAddTaskForm(false);
        },
        onError: () => {
          presentToast({
            title: t('components.data_table.right_sidebar.tasks.errors.task_save_error')
          });
        }
      }
    );
  };

  useEffect(() => {
    setTasksList(tasks);
  }, [tasks]);

  return (
    <>
      <SidePanel.Header>
        <SidePanel.Title className="text-xl font-medium leading-6" data-testid="tasks-title">
          {t('components.data_table.right_sidebar.tasks.title')}
        </SidePanel.Title>
        <SidePanel.Description className="text-xs">
          {t('components.data_table.right_sidebar.tasks.subtitle')}
        </SidePanel.Description>
      </SidePanel.Header>

      {tasks.length === 0 && !shouldRenderAddTaskForm && (
        <div className="mt-2">
          <EmptyState>
            <EmptyState.Icon icon={<TasksIcon />} />
            <EmptyState.Description className="text-emphasis" data-testid="tasks-empty-state-text">
              {t('components.data_table.right_sidebar.tasks.description')}
            </EmptyState.Description>
          </EmptyState>
        </div>
      )}

      {shouldRenderAddTaskForm ? (
        <div className="relative my-2 gap-4 rounded-lg bg-muted p-4">
          <h4 className="mb-4 font-semibold">
            {t('components.data_table.right_sidebar.tasks.new_task')}
          </h4>
          <TaskForm
            table={table}
            onCancel={() => {
              setShouldRenderAddTaskForm(false);
            }}
            onTaskSave={onTaskSave}
          />
        </div>
      ) : (
        <Button
          intent="secondary"
          className="mt-4 w-full"
          onClick={() => setShouldRenderAddTaskForm(true)}
          data-testid="tasks-add-button"
        >
          <Button.Icon icon={PlusIcon} position="left" />
          {t('components.data_table.right_sidebar.tasks.task')}
        </Button>
      )}

      <DndContext
        sensors={optimizedSensors}
        collisionDetection={verticalListCollisionDetection}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={tasksList.map((task: KnackTask) => task.key)}
          strategy={verticalListSortingStrategy}
        >
          <div className="space-y-4" data-testid="tasks-list">
            {tasksList.map((task: KnackTask) => (
              <TaskCardWrapper
                key={task.key}
                task={task}
                table={table}
                hasTableMoreThanOneTask={tasks.length > 1}
              />
            ))}
          </div>
        </SortableContext>

        <DragOverlay>
          {tasksList.map((task: KnackTask) => {
            if (task.key !== beingDraggedTaskKey) {
              return null;
            }

            return (
              <TaskCardWrapper
                key={task.key}
                task={task}
                table={table}
                hasTableMoreThanOneTask={tasks.length > 1}
                isDragOverlay
              />
            );
          })}
        </DragOverlay>
      </DndContext>
    </>
  );
}
