import { nanoid } from 'nanoid';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  type TableDisplayRuleAction,
  type TableView,
  type TableViewColumn,
  type TableViewDisplayRule
} from '@/types/schema/views/TableView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { useViewHelpers } from '@/hooks/helpers/useViewHelpers';

export function useTableDisplayRulesHelpers({
  view,
  sourceObject,
  column
}: {
  view: TableView;
  sourceObject: KnackObject;
  column?: TableViewColumn;
}) {
  const { getViewFields } = useViewHelpers();
  const { hasRoleObjects } = useObjectHelpers();
  const { getDefaultCriteriaOperator, getDefaultCriteriaValue } = useCriteriaHelpers();

  const getAvailableViewFieldsForDisplayRules = () =>
    getViewFields({
      view,
      sourceObject,

      // Ignore the user roles fields if no user roles exist in the application
      fieldTypesToIgnore: !hasRoleObjects() ? ['user_roles'] : undefined
    });

  const getInvalidDisplayRules = () => {
    if (!column) {
      return [];
    }
    const tableViewFieldColumns: TableViewColumn[] = view.columns;

    const invalidDisplayRules: TableViewDisplayRule[] = [];

    column.rules.forEach((displayRule) => {
      // If there are no field columns in the table view, the rule is automatically considered invalid.
      if (tableViewFieldColumns.length === 0) {
        invalidDisplayRules.push(displayRule);
        return;
      }

      // A rule is invalid if the criteria is empty, or if any criteria or actions reference a field that is not in the table view.
      const isInvalid =
        displayRule.criteria.length === 0 ||
        displayRule.criteria.some(
          (criteria) => !tableViewFieldColumns.some((col) => col?.field?.key === criteria.field)
        );

      if (isInvalid) {
        invalidDisplayRules.push(displayRule);
      }
    });

    return invalidDisplayRules;
  };

  const getDefaultDisplayRuleCriteria = () => {
    if (!sourceObject.fields) {
      return undefined;
    }

    const firstAvailableFieldInView = sourceObject.fields[0];
    if (!firstAvailableFieldInView) {
      return undefined;
    }

    const defaultDisplayRuleCriteria: KnackCriteria = {
      field: firstAvailableFieldInView.key,
      operator: getDefaultCriteriaOperator(firstAvailableFieldInView, 'display-rule'),
      value: getDefaultCriteriaValue(firstAvailableFieldInView)
    };

    return defaultDisplayRuleCriteria;
  };

  const getDefaultDisplayRuleAction = () => {
    if (!sourceObject.fields) {
      return undefined;
    }

    const firstAvailableFieldInView = sourceObject.fields[0];

    if (!firstAvailableFieldInView) {
      return undefined;
    }

    const defaultDisplayRuleAction: TableDisplayRuleAction = {
      action: 'text-color',
      value: '',
      color: '#000000',
      bold: false,
      italic: false,
      strikethrough: false,
      icon: {
        color: '',
        align: 'left',
        icon: ''
      }
    };

    return defaultDisplayRuleAction;
  };

  const getDefaultDisplayRule = () => {
    const defaultDisplayRuleCriteria = getDefaultDisplayRuleCriteria();
    const defaultDisplayRuleAction = getDefaultDisplayRuleAction();

    const defaultDisplayRule: TableViewDisplayRule = {
      key: `display_${nanoid(10)}`,
      criteria: defaultDisplayRuleCriteria ? [defaultDisplayRuleCriteria] : [],
      actions: defaultDisplayRuleAction ? [defaultDisplayRuleAction] : []
    };

    return defaultDisplayRule;
  };

  return {
    getAvailableViewFieldsForDisplayRules,
    getDefaultDisplayRule,
    getDefaultDisplayRuleCriteria,
    getDefaultDisplayRuleAction,
    getInvalidDisplayRules
  };
}
