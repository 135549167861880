import { useEffect } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { type IssueData } from 'zod';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type RecordRule } from '@/types/schema/rules/RecordRule';
import { type KnackTask } from '@/types/schema/tasks/KnackTask';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useRecordRuleHelpers } from '@/hooks/helpers/useRecordRuleHelpers';
import { RecordRuleFormFields } from '@/components/record-rule/RecordRuleFormFields';

interface RecordRuleFormProps {
  recordRule: RecordRule;
  sourceObject: KnackObject;
}

export function TaskActionForm({ recordRule, sourceObject }: RecordRuleFormProps) {
  const [t] = useTranslation();

  const { convertFieldNamesToFieldKeys, getFieldByKey } = useFieldHelpers();
  const { validateCriteriaValues } = useCriteriaHelpers();
  const { recordRuleSchema } = useRecordRuleHelpers();

  const recordRuleFormSchema = recordRuleSchema.superRefine((data, context) => {
    const addIssueToErrorContext = (
      path: IssueData['path'],
      message: string | undefined = 'errors.value_required'
    ) => {
      context.addIssue({
        path,
        message: t(message),
        code: 'custom'
      });
    };

    // Transform email subjects and email message from {field name} to {field_key} e.g {First Name} to {field_1}
    if (data.action === 'email') {
      if (data.email?.subject) {
        data.email.subject = convertFieldNamesToFieldKeys(data.email.subject, sourceObject.fields);
      }

      if (data.email?.message) {
        data.email.message = convertFieldNamesToFieldKeys(data.email.message, sourceObject.fields);
      }
    }

    // Validate criteria values
    const criteriaValueErrors = validateCriteriaValues(data.criteria, sourceObject.fields);

    if (criteriaValueErrors.length) {
      criteriaValueErrors.forEach((error) => {
        addIssueToErrorContext(error.path, error.message);
      });
    }

    // Validate email recipients
    if (data.action === 'email' && data.email?.recipients) {
      data.email.recipients.forEach((recipient, recipientIndex) => {
        if (recipient.recipient_type === 'field' && !recipient.field) {
          addIssueToErrorContext([`email.recipients.${recipientIndex}.field`]);
        }
        if (recipient.recipient_type === 'custom' && !recipient.email) {
          addIssueToErrorContext([`email.recipients.${recipientIndex}.email`]);
        }
      });
    }

    // Validate record rule values
    if (data.action !== 'email' && data.values.length !== 0) {
      data.values.forEach((value, valueIndex) => {
        const field = getFieldByKey(value.field);

        if (!field) {
          return;
        }

        if (value.type === 'record' && !value.input) {
          addIssueToErrorContext([`values.${valueIndex}.input`]);
        }

        if (value.type === 'connection' && !value.connection_field) {
          addIssueToErrorContext([`values.${valueIndex}.value`]);
        }
      });
    }
  });

  const parentForm = useFormContext<KnackTask>();

  const form = useForm<RecordRule>({
    resolver: zodResolver(recordRuleFormSchema),
    defaultValues: recordRule,
    errors: parentForm.formState.errors?.action
  });

  const formData = useWatch<RecordRule>({ control: form.control });

  useEffect(() => {
    if (formData) {
      parentForm.setValue('action', formData as RecordRule, { shouldDirty: true });
    }
    parentForm.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (!parentForm.formState.errors?.action) {
      form.clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentForm.formState.errors]);

  return (
    <FormProvider {...form}>
      <RecordRuleFormFields
        recordRule={recordRule}
        sourceObject={sourceObject}
        recordRuleAllowedValueTypes={['record', 'value', 'connection', 'current_date']}
        labels={{
          actionType: t('components.data_table.right_sidebar.tasks.action.action_type'),
          values: t('components.data_table.right_sidebar.tasks.action.set_values'),
          runEveryTime: t('components.data_table.right_sidebar.tasks.action.run_on_every_record')
        }}
      />
    </FormProvider>
  );
}
