import { type BaseKnackField } from '@/types/schema/KnackField';

export const DECIMAL_SEPARATOR_OPTIONS = ['none', 'comma', 'period'] as const;
export const PRECISION_OPTIONS = ['0', '1', '2', '3', '4', '5', '10'] as const;
export const THOUSANDS_SEPARATOR_OPTIONS = ['none', 'comma', 'space', 'period'] as const;
export const ROUNDING_OPTIONS = ['none', 'up', 'down'] as const;
export const BASE_SYMBOL_FORMATS = ['none', '$', '€_after', '€', '£', '%'] as const;
export const ALL_SYMBOL_FORMATS = [...BASE_SYMBOL_FORMATS, 'custom'] as const;

export const SEPARATORS_MAP = {
  none: '',
  comma: ',',
  period: '.',
  space: ' '
};

export interface NumberField extends BaseKnackField {
  type: 'number';
  format?: {
    format: (typeof ALL_SYMBOL_FORMATS)[number];
    mark_decimal: (typeof DECIMAL_SEPARATOR_OPTIONS)[number];
    mark_thousands: (typeof THOUSANDS_SEPARATOR_OPTIONS)[number];
    precision: (typeof PRECISION_OPTIONS)[number];
    rounding: (typeof ROUNDING_OPTIONS)[number];
    pre?: string;
    post?: string;
  };
}
