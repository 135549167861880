import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

interface RecordRuleFormDialogProps {
  formId: string;
  formIntent: 'edit' | 'add';
  onOpenChange: (isOpen: boolean) => void;
  children: React.ReactNode;
}

export function RecordRuleFormDialog({
  formId,
  formIntent,
  onOpenChange,
  children
}: RecordRuleFormDialogProps) {
  const [t] = useTranslation();

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {formIntent === 'edit'
                ? t('components.record_rule_card.edit_modal.title')
                : t('components.record_rule_card.add_modal.title')}
            </Dialog.Title>
            <Dialog.Description className="sr-only">
              {formIntent === 'edit'
                ? t('components.record_rule_card.edit_modal.description')
                : t('components.record_rule_card.add_modal.description')}
            </Dialog.Description>
          </Dialog.Header>

          <div className="mt-6">{children}</div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button form={formId} type="submit" data-testid="view-record-rule-modal-submit">
            {formIntent === 'edit'
              ? t('components.record_rule_card.edit_modal.submit_button')
              : t('components.record_rule_card.add_modal.submit_button')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
