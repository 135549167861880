import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type RecordRule } from '@/types/schema/rules/RecordRule';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackRecordValue } from '@/components/InlineKnackRecordValue';
import { EmptyRuleValue } from '@/pages/pages/settings-panel/view-settings/common/EmptyRuleValue';
import { getRuleMultiSelectValuesAction } from '@/pages/pages/settings-panel/view-settings/common/record-rules/helpers';
import { ViewRecordRuleCardMultiValueContent } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleCardMultiValueContent';

interface ViewRecordRuleCardProps {
  recordRule: RecordRule;
  sourceObjectFieldsMap: Record<KnackFieldKey, KnackField>;
}

export function ViewRecordRuleRecordCard({
  recordRule,
  sourceObjectFieldsMap
}: ViewRecordRuleCardProps) {
  const [t] = useTranslation();
  const { getConnectedFieldInOtherSourceObject, getFieldByKey } = useFieldHelpers();

  return recordRule.values.map((ruleValue, valueIndex) => {
    const valueField = getFieldByKey(ruleValue.field);
    const inputField =
      ruleValue.type === 'record' && ruleValue.input
        ? sourceObjectFieldsMap[ruleValue.input as KnackFieldKey]
        : null;
    const connectedFieldInOtherTable =
      ruleValue.type === 'connection'
        ? getConnectedFieldInOtherSourceObject(ruleValue.connection_field, sourceObjectFieldsMap)
        : null;

    if (!valueField) {
      return (
        <EmptyRuleValue
          // eslint-disable-next-line react/no-array-index-key
          key={`${ruleValue.field}-${valueIndex}`}
        />
      );
    }

    const ruleMultiSelectValuesAction = getRuleMultiSelectValuesAction(ruleValue, valueField);

    return (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={`${ruleValue.field}-${valueIndex}`}
        className="flex flex-wrap items-center gap-1"
      >
        {ruleMultiSelectValuesAction ? (
          <ViewRecordRuleCardMultiValueContent
            ruleAction={recordRule.action}
            ruleMultiSelectValuesAction={ruleMultiSelectValuesAction}
            ruleValue={ruleValue}
            field={valueField}
          />
        ) : (
          <>
            {t('components.record_rule_card.update')}
            <InlineKnackField
              fieldType={valueField.type}
              fieldName={valueField.name}
              className="border-none bg-action"
            />
            {valueField.type !== 'signature' && t('keywords.with')}
            {ruleValue.type === 'current_location' && (
              <span className="inline-flex items-center rounded-md border-none bg-action p-1 align-middle leading-none text-emphasis">
                {t('components.record_rule_card.current_location')}
              </span>
            )}
            {ruleValue.type === 'current_date' && (
              <span className="inline-flex items-center rounded-md border-none bg-action p-1 align-middle leading-none text-emphasis">
                {t('components.record_rule_card.current_date')}
              </span>
            )}
            {ruleValue.type === 'value' &&
              !isNil(ruleValue.value) &&
              valueField.type !== 'signature' && (
                <InlineKnackRecordValue
                  value={ruleValue.value}
                  className="border-none bg-action"
                  field={valueField}
                />
              )}
            {isNil(ruleValue.value) && <EmptyRuleValue />}
            {ruleValue.type === 'record' && inputField && (
              <InlineKnackField
                fieldName={inputField.name}
                fieldType={inputField.type}
                className="border-none bg-action"
              />
            )}
            {ruleValue.type === 'connection' && connectedFieldInOtherTable && (
              <InlineKnackField
                fieldName={connectedFieldInOtherTable.name}
                fieldType={connectedFieldInOtherTable.type}
                className="border-none bg-action"
              />
            )}
          </>
        )}
      </div>
    );
  });
}
