import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { DndContext, DragOverlay, type DragEndEvent, type DragStartEvent } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button } from '@knack/asterisk-react';

import {
  type TableView,
  type TableViewColumn,
  type TableViewDisplayRule
} from '@/types/schema/views/TableView';
import { useDndUtils } from '@/hooks/useDndUtils';
import { DISPLAY_RULES_HELP_LINK } from '@/utils/constants';
import { VerticalListSortableItem } from '@/components/dnd/VerticalListSortableItem';
import { LearnMoreLink } from '@/components/LearnMoreLink';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { TableDisplayRuleCard } from '@/pages/pages/settings-panel/view-settings/table/data-display/display-rules/TableDisplayRuleCard';
import { TableDisplayRuleFormDialog } from '@/pages/pages/settings-panel/view-settings/table/data-display/display-rules/TableDisplayRuleFormDialog';
import { useTableDisplayRulesHelpers } from '@/pages/pages/settings-panel/view-settings/table/data-display/display-rules/useTableDisplayRulesHelpers';

type NewlyInsertedDisplayRule = {
  key: string;
  insertAction: 'new' | 'duplicate';
};

export function TableDisplayRules({
  column,
  updateViewColumn
}: {
  column: TableViewColumn;
  updateViewColumn: (updatedData: Partial<TableViewColumn>) => void;
}) {
  const [t] = useTranslation();

  const { view, sourceObject } = useActiveViewContext<TableView>();
  const { getInvalidDisplayRules, getDefaultDisplayRule } = useTableDisplayRulesHelpers({
    view,
    column,
    sourceObject
  });

  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();

  const [isAddNewRuleModalOpen, setIsAddNewRuleModalOpen] = useState(false);
  const [newlyInsertedRule, setNewlyInsertedRule] = useState<NewlyInsertedDisplayRule | null>(null);
  const [beingDraggedRuleKey, setBeingDraggedRuleKey] = useState<string | null>(null);

  const invalidDisplayRules = getInvalidDisplayRules();
  const totalDisplayRulesCount = column.rules.length ?? 0;
  const validDisplayRulesCount = totalDisplayRulesCount - invalidDisplayRules.length;

  const onRuleAdd = (newDisplayRule: TableViewDisplayRule) => {
    setNewlyInsertedRule({
      key: newDisplayRule.key,
      insertAction: 'new'
    });

    updateViewColumn({
      id: column.id,
      rules: [...column.rules, newDisplayRule]
    });
  };

  const onRuleSave = (updatedDisplayRule: TableViewDisplayRule) => {
    const updatedRules = column.rules.map((rule) =>
      rule.key === updatedDisplayRule.key ? updatedDisplayRule : rule
    );

    updateViewColumn({
      id: column.id,
      rules: updatedRules
    });
  };

  const onRuleDuplicate = (displayRuleToDuplicate: TableViewDisplayRule) => {
    const defaultDisplayRule = getDefaultDisplayRule();
    const duplicatedRule: TableViewDisplayRule = {
      ...displayRuleToDuplicate,
      key: defaultDisplayRule.key
    };

    setNewlyInsertedRule({
      key: duplicatedRule.key,
      insertAction: 'duplicate'
    });

    updateViewColumn({
      id: column.id,
      rules: [...column.rules, duplicatedRule]
    });
  };

  const onRuleDelete = (displayRuleKey: TableViewDisplayRule['key']) => {
    const newRules = column.rules.filter((rule) => rule.key !== displayRuleKey);

    updateViewColumn({
      id: column.id,
      rules: newRules
    });
  };

  const handleDragStart = (event: DragStartEvent) => {
    setBeingDraggedRuleKey(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!column.rules) {
      return;
    }

    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = column.rules.findIndex((rule) => rule.key === active.id) ?? -1;
      const newIndex = column.rules.findIndex((rule) => rule.key === over.id) ?? -1;

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      const newArray = arrayMove(column.rules, oldIndex, newIndex);

      updateViewColumn({
        id: column.id,
        rules: newArray
      });

      setBeingDraggedRuleKey(null);
    }
  };

  return (
    <>
      <div className="mb-2 flex items-center gap-1">
        <span className="text-xs font-medium text-emphasis">
          {t('pages.element_settings.form.categories.form_fields.display_rules.manage_rules')}
        </span>
        <span className="rounded-sm bg-subtle px-1 py-0.5 text-xs font-medium text-default">
          {totalDisplayRulesCount === validDisplayRulesCount
            ? totalDisplayRulesCount
            : t(
                'pages.element_settings.form.categories.form_fields.display_rules.valid_rules_count',
                {
                  validCount: validDisplayRulesCount,
                  totalCount: totalDisplayRulesCount
                }
              )}
        </span>
      </div>
      <p className="mb-4 text-xs text-subtle">
        <Trans
          i18nKey="pages.element_settings.form.categories.form_fields.display_rules.manage_rules_description"
          components={[<LearnMoreLink key="0" href={DISPLAY_RULES_HELP_LINK} />]}
        />
      </p>

      <Button
        data-testid="add-display-rule-button"
        intent="secondary"
        className="w-full"
        onClick={() => setIsAddNewRuleModalOpen(true)}
      >
        <PlusIcon size={16} className="mr-2" />
        {t('pages.element_settings.form.categories.form_fields.display_rules.add_rule')}
      </Button>

      {column.rules && column.rules.length > 0 && (
        <DndContext
          sensors={optimizedSensors}
          collisionDetection={verticalListCollisionDetection}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext
            items={column.rules.map((rule) => rule.key)}
            strategy={verticalListSortingStrategy}
          >
            <div className="mt-4 space-y-2">
              {column.rules.map((displayRule, ruleIndex) => (
                <VerticalListSortableItem key={displayRule.key} id={displayRule.key}>
                  <TableDisplayRuleCard
                    displayRule={displayRule}
                    sourceObject={sourceObject}
                    ruleNumber={ruleIndex + 1}
                    isInvalid={invalidDisplayRules.some(
                      (invalidRule) => invalidRule.key === displayRule.key
                    )}
                    shouldScrollIntoView={newlyInsertedRule?.key === displayRule.key}
                    onRuleSave={onRuleSave}
                    onRuleDuplicate={onRuleDuplicate}
                    onRuleDelete={onRuleDelete}
                  />
                </VerticalListSortableItem>
              ))}
            </div>
          </SortableContext>

          <DragOverlay>
            {column.rules.map((displayRule, ruleIndex) => {
              if (displayRule.key !== beingDraggedRuleKey) {
                return null;
              }

              return (
                <TableDisplayRuleCard
                  key={displayRule.key}
                  isDragOverlay
                  displayRule={displayRule}
                  sourceObject={sourceObject}
                  ruleNumber={ruleIndex + 1}
                  isInvalid={invalidDisplayRules.some(
                    (invalidRule) => invalidRule.key === displayRule.key
                  )}
                  onRuleSave={onRuleSave}
                  onRuleDuplicate={onRuleDuplicate}
                  onRuleDelete={onRuleDelete}
                />
              );
            })}
          </DragOverlay>
        </DndContext>
      )}

      {isAddNewRuleModalOpen && (
        <TableDisplayRuleFormDialog
          onOpenChange={setIsAddNewRuleModalOpen}
          displayRule={getDefaultDisplayRule()}
          sourceObject={sourceObject}
          formIntent="add"
          onRuleSave={(newDisplayRule) => {
            onRuleAdd(newDisplayRule);
          }}
        />
      )}
    </>
  );
}
