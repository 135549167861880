import { Link } from 'react-router-dom';
import { Button } from '@knack/asterisk-react';

type WelcomeLinkProps = {
  animationDelay: number;
  href: string;
  children: React.ReactNode;
};

export function WelcomeLink({ animationDelay, href, children }: WelcomeLinkProps) {
  return (
    <Button
      className="w-full animate-slideDownAndFade opacity-0 fill-mode-forwards"
      style={{ animationDelay: `${animationDelay}ms` }}
      data-testid="welcome-button"
      asChild
    >
      <Link to={href}>{children}</Link>
    </Button>
  );
}
