import { type KnackCriteriaWithValueType } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type Field } from '@/components/data-table/display/fields/Field';

export const RECORD_RULE_MULTI_SELECT_ACTION_TYPES = ['append', 'remove'] as const;
export const RECORD_RULE_ACTION_TYPES = ['record', 'connection', 'insert', 'email'] as const;
export const RECORD_RULE_VALUE_TYPES = [
  'record',
  'value',
  'connection',
  'current_date',
  'current_location',
  'user'
] as const;

export type RecordRuleValueType = (typeof RECORD_RULE_VALUE_TYPES)[number];
export type RecordRuleActionType = (typeof RECORD_RULE_ACTION_TYPES)[number];
export type RecordRuleMultiSelectActionType =
  (typeof RECORD_RULE_MULTI_SELECT_ACTION_TYPES)[number];

export const EMAIL_RECIPIENT_MODE_OPTIONS = ['to', 'cc', 'bcc', 'reply_to'] as const;
export const EMAIL_RECIPIENT_TYPE_OPTIONS = ['custom', 'field'] as const;

export type RecordRuleEmailRecipientMode = (typeof EMAIL_RECIPIENT_MODE_OPTIONS)[number];
export type RecordRuleEmailRecipientType = (typeof EMAIL_RECIPIENT_TYPE_OPTIONS)[number];

interface RecordRuleValueBase {
  field: KnackFieldKey; // The field that will be updated
}

interface RecordRuleRecordValue extends RecordRuleValueBase {
  type: 'record';
  value: string;
  input: KnackFieldKey | string; // The field where the value is coming from
}

interface RecordRulePlainValue extends RecordRuleValueBase {
  type: 'value';
  value: Field['rawValue'];

  // Only used for:
  // - Multiple choice fields with a multi select format (e.g. 'multi' or 'checkboxes')
  // - Connection fields with a 'has' relationship set to 'many'
  action?: RecordRuleMultiSelectActionType | '';
}

interface RecordRuleConnectionValue extends RecordRuleValueBase {
  type: 'connection';
  value: string;
  connection_field?: `${KnackFieldKey}-${KnackFieldKey}`; // The connection field where the value is coming from
}

interface RecordRuleCurrentDateValue extends RecordRuleValueBase {
  type: 'current_date';
  value: string;
}

interface RecordRuleCurrentLocationValue extends RecordRuleValueBase {
  type: 'current_location';
  value: string;
}

interface RecordRuleUserValue extends RecordRuleValueBase {
  type: 'user';
  value: string;
}

export type RecordRuleValue =
  | RecordRuleRecordValue
  | RecordRulePlainValue
  | RecordRuleConnectionValue
  | RecordRuleCurrentDateValue
  | RecordRuleCurrentLocationValue
  | RecordRuleUserValue;

export interface RecordRule {
  action: RecordRuleActionType;
  connection?: RecordRuleConnectionKey;
  criteria: KnackCriteriaWithValueType[];
  values: RecordRuleValue[];
  email?: RecordRuleEmail;
  key: string;
}

export type RecordRuleConnectionKey = `${KnackObject['key']}.${KnackFieldKey}`;

export interface RecordRuleEmail {
  from_email: string;
  from_name: string;
  message: string;
  recipients: RecordRuleEmailRecipient[];
  subject: string;
}

export interface RecordRuleEmailRecipient {
  email: string;
  recipient_mode: RecordRuleEmailRecipientMode;
  recipient_type: RecordRuleEmailRecipientType;
  field: KnackFieldKey | `${KnackFieldKey}-${KnackFieldKey}` | null;
}
