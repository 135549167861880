import { t } from 'i18next';
import { nanoid } from 'nanoid';

import {
  VIEW_COLUMN_ID_PREFIX,
  VIEW_GROUP_ID_PREFIX,
  type BuilderViewSourceSchema
} from '@/types/schema/BuilderView';
import { MapStartingPoint, MapUnit, type MapView } from '@/types/schema/views/MapView';
import { type ViewSource } from '@/pages/pages/page-editor/add-view/helpers/useViewSources';
import { generateDetailsViewInputs } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/detailsViewSchema';
import { generateNewViewKey } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/generateNewViewKey';

export function getMapViewSchema(
  viewSource: ViewSource,
  viewSourceSchema: BuilderViewSourceSchema
) {
  const objectName =
    viewSource.recordDisplayQuantity === 'many'
      ? viewSource.object.inflections.plural
      : viewSource.object.inflections.singular;
  const viewTitle = t('views.new_view_defaults.map.name', {
    objectName
  });

  const addressField = viewSource.object.fields.find((field) => field.type === 'address');
  if (!addressField) {
    throw new Error('An address field is required to create a map view');
  }

  const titleFieldKey = viewSource.object.fields[0].key;

  const columns = [
    {
      id: `${VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
      width: 100,
      groups: [
        {
          id: `${VIEW_GROUP_ID_PREFIX}${nanoid(10)}`,
          columns: [
            generateDetailsViewInputs({
              viewSourceObject: viewSource.object,
              ignoreFields: [titleFieldKey]
            })
          ]
        }
      ]
    }
  ];

  const view: Partial<MapView> = {
    name: viewTitle,
    title: viewTitle,
    source: viewSourceSchema,
    type: 'map',
    key: generateNewViewKey(),
    address_field: {
      key: addressField.key
    },
    title_field: {
      key: titleFieldKey
    },
    starting_point: MapStartingPoint.Blank,
    map_width: 600,
    map_height: 400,
    list_width: 300,
    rows_per_page: '10',
    default_range: 10,
    details: {
      columns,
      label_format: 'none',
      list_layout: 'one-column',
      layout: 'full',
      filter_fields: 'view',
      filter_type: 'fields',
      preset_filters: [],
      menu_filters: [],
      allow_preset_filters: false
    },
    columns: [],
    units: MapUnit.Miles,
    pin_color_default: '#FE7569',
    pin_colors: []
  };

  return view;
}
