import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';

import { AddOption } from '@/components/field-settings/multiple-choice/AddOption';
import { DeleteOptionBanner } from '@/components/field-settings/multiple-choice/DeleteOptionBanner';
import { OptionsDraggableList } from '@/components/field-settings/multiple-choice/OptionsDraggableList';
import { OptionsQuickEdit } from '@/components/field-settings/multiple-choice/OptionsQuickEdit';
import { SortingSwitch } from '@/components/field-settings/multiple-choice/SortingSwitch';
import { TypeSelect } from '@/components/field-settings/multiple-choice/TypeSelect';

export type MultipleChoiceDraggableOption = {
  id: string;
  option: string;
};

type FormContentProps = {
  options: MultipleChoiceDraggableOption[];
  isImmutable: boolean;
  isNewField: boolean;
};

const DEFAULT_NEW_OPTION_VALUE = 'Another Choice';

export function FormContent({
  options: optionsWithIds,
  isImmutable,
  isNewField
}: FormContentProps) {
  const [t] = useTranslation();
  const { setValue } = useFormContext();
  const [options, setOptions] = useState(optionsWithIds);
  const [areOptionsModified, setAreOptionsModified] = useState(false);
  const [isQuickEditMode, setIsQuickEditMode] = useState(false);

  const onAddOption = () => {
    const newOption = {
      id: nanoid(5),
      option: `${DEFAULT_NEW_OPTION_VALUE} ${options.length + 1}`
    };
    setOptions([...options, newOption]);
    setValue('format.sorting', 'custom');
  };

  const onSortOptions = () => {
    setOptions([...options].sort((a, b) => a.option.localeCompare(b.option)));
  };

  useEffect(() => {
    setValue(
      'format.options',
      options.map(({ option }) => option)
    );
  }, [options, setValue]);

  return (
    <div className="flex flex-col gap-4" data-testid="options-list-container">
      {!isImmutable && <TypeSelect />}
      {isQuickEditMode ? (
        <OptionsQuickEdit
          options={options}
          setOptions={(val) => {
            setOptions(val);
            setAreOptionsModified(true);
          }}
          onCloseQuickEdit={() => setIsQuickEditMode(false)}
        />
      ) : (
        <OptionsDraggableList
          options={options}
          setOptions={(val) => {
            setOptions(val);
            setAreOptionsModified(true);
          }}
          isImmutable={isImmutable}
        />
      )}
      {!isImmutable && !isQuickEditMode && (
        <div className="flex gap-2">
          <Button
            className="p-1"
            data-testid="quick-edit-button"
            intent="minimal"
            onClick={() => setIsQuickEditMode(true)}
          >
            {t('components.data_table.attributes.field_settings.multiple_choice.quick_edit')}
          </Button>
          <AddOption onAddOption={onAddOption} />
        </div>
      )}
      {!isNewField && areOptionsModified && <DeleteOptionBanner />}
      <SortingSwitch onSortOptions={onSortOptions} />
    </div>
  );
}
