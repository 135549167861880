import { Trans } from 'react-i18next';

import { type KnackField } from '@/types/schema/KnackField';
import { type RecordRuleActionType, type RecordRuleValue } from '@/types/schema/rules/RecordRule';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackRecordValue } from '@/components/InlineKnackRecordValue';

interface ViewRecordRuleCardMultiValueContentProps {
  ruleAction: RecordRuleActionType;
  ruleMultiSelectValuesAction: 'append' | 'replace' | 'remove';
  ruleValue: RecordRuleValue;
  field: KnackField;
}

export function ViewRecordRuleCardMultiValueContent({
  ruleAction,
  ruleMultiSelectValuesAction,
  ruleValue,
  field
}: ViewRecordRuleCardMultiValueContentProps) {
  const getInlineKnackRecordValue = (key: string) => (
    <InlineKnackRecordValue
      key={key}
      value={ruleValue.value || '-'}
      className="border-none bg-action"
      field={field}
    />
  );

  const getInlineKnackField = (key: string) => (
    <InlineKnackField
      key={key}
      fieldName={field.name}
      fieldType={field.type}
      className="border-none bg-action"
    />
  );

  const i18nKey =
    ruleAction === 'record'
      ? `components.record_rule_card.multiselect_values_action_this_record.${ruleMultiSelectValuesAction}`
      : `components.record_rule_card.multiselect_values_action_connected_record.${ruleMultiSelectValuesAction}`;

  return (
    <>
      {ruleMultiSelectValuesAction === 'append' && (
        <Trans
          i18nKey={i18nKey}
          components={[getInlineKnackRecordValue('0'), getInlineKnackField('1')]}
        />
      )}
      {ruleMultiSelectValuesAction === 'replace' && (
        <Trans
          i18nKey={i18nKey}
          components={[getInlineKnackField('0'), getInlineKnackRecordValue('1')]}
        />
      )}
      {ruleMultiSelectValuesAction === 'remove' && (
        <Trans
          i18nKey={i18nKey}
          components={[getInlineKnackRecordValue('0'), getInlineKnackField('1')]}
        />
      )}
    </>
  );
}
