import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { HubspotChatProvider, shouldInstallHubSpot } from '@/HubspotChatProvider';
import { IntercomProvider, shouldInstallIntercom } from '@/IntercomProvider';

type ChatBotProviderProps = {
  children: React.ReactNode;
};

export function ChatBotProvider({ children }: ChatBotProviderProps) {
  const { data: application } = useApplicationQuery();

  if (!application) {
    return children;
  }

  if (shouldInstallIntercom() && application.account.isTrial) {
    return <IntercomProvider>{children}</IntercomProvider>;
  }

  if (shouldInstallHubSpot() && !application.account.isTrial) {
    return <HubspotChatProvider>{children}</HubspotChatProvider>;
  }

  return children;
}
