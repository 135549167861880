import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import { type FormViewSubmitRule } from '@/types/schema/views/FormView';

export function useSubmitRuleHelpers() {
  const [t] = useTranslation();

  const getDefaultSubmitRule = (ruleMessage?: string) => {
    const submitRule: FormViewSubmitRule = {
      key: `submit_${nanoid(10)}`,
      criteria: [],
      action: 'message',
      is_default: false,
      reload_show: false,
      message: ruleMessage ?? t('views.new_view_defaults.form.submit_rule_message')
    };

    return submitRule;
  };

  return {
    getDefaultSubmitRule
  };
}
