import { useEffect } from 'react';
import { Controller, useFormContext, type Path } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type RecordRule, type RecordRuleConnectionKey } from '@/types/schema/rules/RecordRule';
import { type KnackTask } from '@/types/schema/tasks/KnackTask';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';

type BuilderTasksActionPath = keyof Pick<KnackTask, 'action'>;
type RecordRuleConnectionPath = keyof Pick<RecordRule, 'connection'>;

type BuilderRecordRuleConnectionPath =
  | RecordRuleConnectionPath
  | Extract<Path<Pick<KnackTask, 'action'>>, 'action.connection'>;

interface RecordRuleConnectionSelectProps {
  sourceObject: KnackObject;
  formFieldNamePrefix?: BuilderTasksActionPath;
  isInsert: boolean;
  onChange?: (value: RecordRuleConnectionKey) => void;
}

export function RecordRuleConnectionSelect({
  sourceObject,
  formFieldNamePrefix,
  onChange,
  isInsert
}: RecordRuleConnectionSelectProps) {
  const [t] = useTranslation();

  const { getObjectByKey } = useObjectHelpers();
  const { getFieldByKey } = useFieldHelpers();
  const { setValue, getValues, control } = useFormContext<KnackTask | RecordRule>();

  const formFieldName: BuilderRecordRuleConnectionPath = formFieldNamePrefix
    ? `${formFieldNamePrefix}.connection`
    : 'connection';

  const sourceObjectConnections = [
    ...sourceObject.connections.inbound,
    ...sourceObject.connections.outbound
  ];

  // Set the default value if the field is empty
  useEffect(() => {
    if (!getValues(formFieldName) && sourceObjectConnections.length > 0) {
      setValue(
        formFieldName,
        `${sourceObjectConnections[0].object}.${sourceObjectConnections[0].key}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="record-rule-connection-select" className="font-medium">
        {t('components.rules.record_rules.connections.label')}
      </Label>
      <Controller
        control={control}
        name={formFieldName}
        render={({ field: { value, onChange: onFieldChange } }) => (
          <Select
            onValueChange={(val: RecordRuleConnectionKey) => {
              onFieldChange(val);

              if (onChange) {
                onChange(val);
              }
            }}
            value={value}
          >
            <Select.Trigger
              id="record-rule-connection-select"
              placeholder={t('actions.select')}
              className="w-full"
            />
            <Select.Content>
              {sourceObjectConnections.map((connection, index) => {
                const connectionObject = getObjectByKey(connection.object);
                const connectionField = getFieldByKey(connection.key);

                if (!connectionObject || !connectionField) {
                  return null;
                }

                // Ecommerce records can't be inserted or updated
                if (connectionObject.type === 'EcommercePaymentObject') {
                  return null;
                }

                let connectionOptionLabelKey =
                  'components.rules.record_rules.connections.insert_connected_table';

                if (!isInsert) {
                  const isOutgoingConnection = sourceObject.fields.some(
                    (f) => f.key === connection.key
                  );
                  const isSingleConnection = isOutgoingConnection
                    ? connection.has === 'one'
                    : connection.belongs_to === 'one';
                  connectionOptionLabelKey = isSingleConnection
                    ? 'components.rules.record_rules.connections.update_connected_table'
                    : 'components.rules.record_rules.connections.update_connected_table_each';
                }

                return (
                  <Select.Item
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${connection.object}-${connection.key}-${index}`}
                    value={`${connection.object}.${connection.key}`}
                    data-testid={`record-rule-connection-${connection.object}-${connection.key}`}
                  >
                    <Trans
                      i18nKey={connectionOptionLabelKey}
                      values={{
                        connectedTable: getObjectByKey(connection.object)?.inflections.singular,
                        connectedField: getFieldByKey(connection.key)?.name
                      }}
                    />
                  </Select.Item>
                );
              })}
            </Select.Content>
          </Select>
        )}
      />
    </div>
  );
}
