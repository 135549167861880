import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { type IssueData } from 'zod';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  type RecordRule,
  type RecordRuleActionType,
  type RecordRuleValueType
} from '@/types/schema/rules/RecordRule';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useRecordRuleHelpers } from '@/hooks/helpers/useRecordRuleHelpers';
import { type RecordRuleFormFieldsLabels } from '@/components/record-rule/helpers';
import { RecordRuleFormFields } from '@/components/record-rule/RecordRuleFormFields';

interface RecordRuleFormProps {
  recordRule: RecordRule;
  sourceObject: KnackObject;
  formId: string;
  onSubmit: (updatedRecordRule: RecordRule) => void;
  sourcePage?: BuilderPage;
  recordRuleActionTypes?: RecordRuleActionType[];
  recordRuleAllowedValueTypes?: RecordRuleValueType[];
  canCriteriaValuesBeField?: boolean;
  labels?: Partial<RecordRuleFormFieldsLabels>;
}

export function RecordRuleForm({
  recordRule,
  sourceObject,
  formId,
  onSubmit,
  sourcePage,
  recordRuleActionTypes,
  recordRuleAllowedValueTypes,
  canCriteriaValuesBeField,
  labels
}: RecordRuleFormProps) {
  const [t] = useTranslation();

  const { convertFieldNamesToFieldKeys, getFieldByKey } = useFieldHelpers();
  const { validateCriteriaValues } = useCriteriaHelpers();
  const { recordRuleSchema } = useRecordRuleHelpers();

  const recordRuleFormSchema = recordRuleSchema.superRefine((data, context) => {
    const addIssueToErrorContext = (
      path: IssueData['path'],
      message: string | undefined = 'errors.value_required'
    ) => {
      context.addIssue({
        path,
        message: t(message),
        code: 'custom'
      });
    };

    // Transform email subjects and email message from {field name} to {field_key} e.g {First Name} to {field_1}
    if (data.action === 'email') {
      if (data.email?.subject) {
        data.email.subject = convertFieldNamesToFieldKeys(data.email.subject, sourceObject.fields);
      }

      if (data.email?.message) {
        data.email.message = convertFieldNamesToFieldKeys(data.email.message, sourceObject.fields);
      }
    }

    // Validate criteria values
    const criteriaValueErrors = validateCriteriaValues(data.criteria, sourceObject.fields);

    if (criteriaValueErrors.length) {
      criteriaValueErrors.forEach((error) => {
        addIssueToErrorContext(error.path, error.message);
      });
    }

    // Validate email recipients
    if (data.action === 'email' && data.email?.recipients) {
      data.email.recipients.forEach((recipient, recipientIndex) => {
        if (recipient.recipient_type === 'field' && !recipient.field) {
          addIssueToErrorContext([`email.recipients.${recipientIndex}.field`]);
        }
        if (recipient.recipient_type === 'custom' && !recipient.email) {
          addIssueToErrorContext([`email.recipients.${recipientIndex}.email`]);
        }
      });
    }

    // Validate record rule values
    if (data.action !== 'email' && data.values.length !== 0) {
      data.values.forEach((value, valueIndex) => {
        const field = getFieldByKey(value.field);

        if (!field) {
          return;
        }

        if (value.type === 'record' && !value.input) {
          addIssueToErrorContext([`values.${valueIndex}.input`]);
        }

        if (value.type === 'connection' && !value.connection_field) {
          addIssueToErrorContext([`values.${valueIndex}.value`]);
        }
      });
    }
  });

  const form = useForm<RecordRule>({
    resolver: zodResolver(recordRuleFormSchema),
    defaultValues: recordRule
  });

  const handleSubmit = (updatedRecordRule: RecordRule) => {
    onSubmit(updatedRecordRule);
  };

  return (
    <FormProvider {...form}>
      <form id={formId} className="w-full" onSubmit={form.handleSubmit(handleSubmit)}>
        <RecordRuleFormFields
          recordRule={recordRule}
          sourceObject={sourceObject}
          sourcePage={sourcePage}
          recordRuleActionTypes={recordRuleActionTypes}
          recordRuleAllowedValueTypes={recordRuleAllowedValueTypes}
          canCriteriaValuesBeField={canCriteriaValuesBeField}
          labels={labels}
        />
      </form>
    </FormProvider>
  );
}
