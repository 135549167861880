import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

interface DisplayRuleColorPickerProps {
  id?: string;
  isHexColor?: boolean;
  value: string;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
}

export function DisplayRuleColorPicker({
  id,
  isHexColor = false,
  value,
  onChange,
  onBlur
}: DisplayRuleColorPickerProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const colorValue = isHexColor ? value.substring(1) : value;

  return (
    <div className="flex w-full items-center rounded-lg border border-default bg-input">
      <button
        type="button"
        className={cn(
          'h-auto rounded-l-lg border-default p-2 hover:cursor-pointer hover:bg-muted',
          {
            'border-r': !isHexColor
          }
        )}
        onClick={handleClick}
        aria-label={t('components.color_picker.color_picker')}
      >
        <div
          className="border-muted size-6 rounded-md border p-px py-0"
          style={{ backgroundColor: value }}
        >
          <input
            ref={inputRef}
            type="color"
            value={colorValue}
            onChange={(e) => onChange(e.target.value)}
            className="invisible"
            tabIndex={-1}
            {...(onBlur && {
              onBlur: (e) => onBlur(isHexColor ? `#${e.target.value}` : e.target.value)
            })}
          />
        </div>
      </button>
      {isHexColor && <span className="ml-1 text-sm text-subtle">#</span>}
      <Input
        className="rounded-l-none border-0 text-sm focus:-outline-offset-1"
        value={colorValue}
        onChange={(e) => onChange(isHexColor ? `#${e.target.value}` : e.target.value)}
        {...(id && { id })}
        {...(isHexColor && {
          title: t('components.color_picker.color_input_title'),
          pattern: '^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$'
        })}
        {...(onBlur && {
          onBlur: (e) => onBlur(isHexColor ? `#${e.target.value}` : e.target.value)
        })}
      />
    </div>
  );
}
