import { type KnackObject } from '@/types/schema/KnackObject';
import { type RecordRule } from '@/types/schema/rules/RecordRule';

export const TASK_RUN_STATUS_OPTIONS = ['running', 'paused'] as const;
export const TASK_TYPE = 'actions';
export const TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS = ['daily', 'weekly', 'monthly'] as const;

export type TaskRunStatus = (typeof TASK_RUN_STATUS_OPTIONS)[number];
export type TaskScheduleRepeatFrequency = (typeof TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS)[number];

export type TaskKey = `task_${string}`;

export interface KnackTask {
  name: string;
  action: RecordRule;
  run_status: TaskRunStatus;
  type: typeof TASK_TYPE;
  object_key: KnackObject['key'];
  schedule: {
    repeat: TaskScheduleRepeatFrequency;
    date: string;
    time: string;
  };
  key: TaskKey;
}

export type TaskHistoryStatus = 'Finished' | 'Canceled' | 'Finished (Run Manually)' | 'Failed';

export interface TaskHistoryData {
  id: string;
  object_key: KnackObject['key'];
  started_at: string;
  processed_count: number;
  total_count: number;
  status: TaskHistoryStatus;
  ended_at: string;
}
