import { useState } from 'react';
import {
  HiViewColumns as ColumnsIcon,
  HiTableCells as FieldsIcon,
  HiCog6Tooth as GeneralSettingsIcon
} from 'react-icons/hi2';
import { t } from 'i18next';

import { type ListView } from '@/types/schema/views/ListView';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { CommonSourceBanner } from '@/pages/pages/settings-panel/view-settings/common/CommonSourceBanner';
import { ViewWithDetailsActionsSettingsCategory } from '@/pages/pages/settings-panel/view-settings/common/details-actions/ViewWithDetailsActionsSettingsCategory';
import { ListDataDisplaySettingsCategory } from '@/pages/pages/settings-panel/view-settings/list/ListDataDisplaySettingsCategory';
import { ListGeneralSettingsCategory } from '@/pages/pages/settings-panel/view-settings/list/ListGeneralSettingsCategory';
import { ListInputEditModal } from '@/pages/pages/settings-panel/view-settings/list/ListInputEditModal';
import {
  ViewSettingsCategoryList,
  type ViewSettingsCategoryItem
} from '@/pages/pages/settings-panel/view-settings/ViewSettingsCategoryList';

type ListSettingsCategoryId = 'general-settings' | 'data-display' | 'actions' | 'styling';
type ListSettingsCategoryItem = ViewSettingsCategoryItem<ListSettingsCategoryId>;

function getListSettingsCategories() {
  const LIST_SETTINGS_CATEGORIES: ListSettingsCategoryItem[] = [
    {
      id: 'general-settings',
      name: t('pages.element_settings.common.categories.general_settings.name'),
      description: t('pages.element_settings.list.categories.general_settings.description'),
      icon: GeneralSettingsIcon
    },
    {
      id: 'data-display',
      name: t('pages.element_settings.common.categories.data_display.name'),
      description: t('pages.element_settings.common.categories.data_display.description'),
      icon: FieldsIcon
    },
    {
      id: 'actions',
      name: t('pages.element_settings.list.categories.actions.name'),
      description: t('pages.element_settings.list.categories.actions.description'),
      icon: ColumnsIcon
    }
  ];

  return LIST_SETTINGS_CATEGORIES;
}

function getPanelTitle(
  activeCategoryId: ListSettingsCategoryId | null,
  categories: ListSettingsCategoryItem[]
) {
  const activeCategory = activeCategoryId
    ? categories.find((category) => category.id === activeCategoryId)
    : null;

  return activeCategory ? activeCategory.name : t('pages.element_settings.list.heading');
}

export function ListViewSettings() {
  const { view } = useActiveViewContext<ListView>();
  const [activeCategoryId, setActiveCategoryId] = useState<ListSettingsCategoryId | null>(null);

  const listSettingsCategories = getListSettingsCategories();
  const panelTitle = getPanelTitle(activeCategoryId, listSettingsCategories);

  return (
    <>
      <CollapsiblePanel.Content
        title={panelTitle}
        onBack={activeCategoryId ? () => setActiveCategoryId(null) : undefined}
        shouldHideDivider={activeCategoryId === 'data-display'}
      >
        {activeCategoryId ? (
          <>
            {activeCategoryId === 'general-settings' && <ListGeneralSettingsCategory />}
            {activeCategoryId === 'data-display' && <ListDataDisplaySettingsCategory />}
            {activeCategoryId === 'actions' && <ViewWithDetailsActionsSettingsCategory />}
          </>
        ) : (
          <>
            <CommonSourceBanner view={view} />
            <ViewSettingsCategoryList
              categories={listSettingsCategories}
              onCategorySelect={(selectedCategoryId: string) => {
                setActiveCategoryId(selectedCategoryId as ListSettingsCategoryId);
              }}
            />
          </>
        )}
      </CollapsiblePanel.Content>
      <ListInputEditModal />
    </>
  );
}
