import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { useValidateQueryParam } from '@/hooks/useValidateQueryParam';
import { ROUTES } from '@/Router';
import { WelcomeLink } from './WelcomeLink';

interface TemplateButtonProps {
  animationDelay: number;
}

const buttonTypes = {
  preview: 'preview',
  builder: 'builder'
} as const;

export function TemplateButton({ animationDelay }: TemplateButtonProps) {
  const [t] = useTranslation();
  const type = useValidateQueryParam('button-type', buttonTypes);

  if (!type) {
    return <Navigate to={ROUTES.ROOT} replace />;
  }

  return (
    <WelcomeLink
      animationDelay={animationDelay}
      href={type === buttonTypes.preview ? `${ROUTES.PAGES}?quickView=true` : ROUTES.ROOT}
    >
      {t(`components.welcome.template.button_${type}`)}
    </WelcomeLink>
  );
}
