import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useValidateQueryParam<T extends Record<string, unknown>>(
  key: string,
  validValues: T
): T[keyof T] | undefined {
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    const queryValue = searchParams.get(key);

    if (!queryValue || !(queryValue in validValues)) {
      return undefined;
    }

    return validValues[queryValue as keyof T];
  }, [key, searchParams, validValues]);
}
