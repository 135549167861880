import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

import { type KnackTask } from '@/types/schema/tasks/KnackTask';

export function TaskStatusFormSection() {
  const [t] = useTranslation();

  const { control } = useFormContext<KnackTask>();

  return (
    <div className="flex flex-col gap-2">
      <Label className="font-medium">
        {t('components.data_table.right_sidebar.tasks.task_status.title')}
      </Label>
      <Controller
        control={control}
        name="run_status"
        render={({ field: { value, onChange } }) => (
          <div className="flex items-center gap-2">
            <Switch
              id="task-run-status-switch"
              data-testid="task-run-status-switch"
              checked={value === 'running'}
              onCheckedChange={(checked) => {
                onChange(checked ? 'running' : 'paused');
              }}
            />
            <Label className="font-medium" htmlFor="task-run-status-switch">
              {value === 'running'
                ? t('components.data_table.right_sidebar.tasks.task_status.running')
                : t('components.data_table.right_sidebar.tasks.task_status.paused')}
            </Label>
          </div>
        )}
      />
    </div>
  );
}
