import { useEffect, useState } from 'react';

import { type Session } from '@/types/session';
import { useHubspotMutation } from '@/hooks/api/mutations/useHubspotMutation';

export function shouldInstallHubSpot() {
  return import.meta.env.PUBLIC_HUBSPOT_APP_ID !== '';
}

const onConversationsAPIReady = () => {
  window.HubSpotConversations.widget.load();
};

window.hsConversationsSettings = {
  loadImmediately: false
};
const loadHubspotScript = (): Promise<void> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = () => {
      if (window.HubSpotConversations) {
        onConversationsAPIReady();
      } else {
        window.hsConversationsOnReady = [onConversationsAPIReady];
      }
      resolve();
    };
    script.onerror = (error) => reject(error);
    document.body.appendChild(script);
  });

interface HubspotChatInitProps {
  user?: Session['user'];
  children: React.ReactNode;
}

interface HubspotChatSetupProps {
  user?: Session['user'];
  children: React.ReactNode;
}

function HubspotChatInit({ user, children }: HubspotChatInitProps) {
  const hubspotMutation = useHubspotMutation();

  const [hasHubspotChatContentLoaded, setHasHubspotChatContentLoaded] = useState(false);
  const [hasHubspotScriptLoaded, setHasHubspotScriptLoaded] = useState(false);

  useEffect(() => {
    const loadScript = async () => {
      try {
        await loadHubspotScript();
        setHasHubspotScriptLoaded(true);
      } catch (error) {
        setHasHubspotScriptLoaded(false);
      }
    };

    void loadScript();
  }, []);

  useEffect(() => {
    if (hasHubspotScriptLoaded && user && !hasHubspotChatContentLoaded) {
      setHasHubspotChatContentLoaded(true);
      const { email, firstName, lastName } = user;

      hubspotMutation.mutate(
        {
          email,
          firstName,
          lastName
        },
        {
          onSuccess: (token: string) => {
            if (token) {
              window.hsConversationsSettings = {
                identificationEmail: email,
                identificationToken: token
              };
              if (!window.HubSpotConversations) {
                window.hsConversationsOnReady = [
                  () => {
                    window.HubSpotConversations.widget.load();
                  }
                ];
              }
              const status = window.HubSpotConversations?.widget.status();

              if (status?.loaded) {
                window.HubSpotConversations.widget.refresh();
              } else {
                window.HubSpotConversations.widget.load();
              }
            }
          }
        }
      );
    }
  }, [user, hasHubspotChatContentLoaded, hubspotMutation, hasHubspotScriptLoaded]);

  return children;
}

export function HubspotChatProvider({ user, children }: HubspotChatSetupProps) {
  if (!user || !import.meta.env.PUBLIC_HUBSPOT_APP_ID) {
    return children;
  }

  return <HubspotChatInit user={user}>{children}</HubspotChatInit>;
}
