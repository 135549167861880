import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type MapPinColor } from '@/types/schema/views/MapView';
import { PinRuleCard } from '@/pages/pages/settings-panel/view-settings/map/pin-rules/PinRuleCard';
import { PinRuleForm } from '@/pages/pages/settings-panel/view-settings/map/pin-rules/PinRuleForm';

type PinRulesProps = {
  sourceObject: KnackObject;
  pinRules: MapPinColor[];
  pinColorDefault: string;
  onPinRulesChange: (pinRules: MapPinColor[]) => void;
};

export function PinRules({
  sourceObject,
  pinRules,
  pinColorDefault,
  onPinRulesChange
}: PinRulesProps) {
  const [t] = useTranslation();
  const [isAddingFilter, setIsAddingFilter] = useState(false);

  const onFilterSave = (updatedFilter: MapPinColor, updatedFilterIndex?: number) => {
    if (updatedFilterIndex === undefined) {
      const rules = [...pinRules, updatedFilter];
      onPinRulesChange(rules);
      setIsAddingFilter(false);
      return;
    }

    const rules = pinRules.map((rule, index) => {
      if (index === updatedFilterIndex) {
        return updatedFilter;
      }
      return rule;
    });
    onPinRulesChange(rules);
  };

  const onFilterDelete = (filterIndex: number) => {
    const rules = pinRules.filter((_, index) => index !== filterIndex);
    onPinRulesChange(rules);
  };

  const defaultPinRule: MapPinColor = {
    field: sourceObject.fields[0].key,
    operator: 'contains',
    value: '',
    color: pinColorDefault
  };

  return (
    <div>
      <div className="mb-2 block font-medium">
        {t('pages.element_settings.map.styling_settings.color_rules')}
      </div>
      <p className="mb-2 mt-2 text-xs text-subtle">
        {pinRules.length > 0
          ? t('pages.element_settings.map.styling_settings.color_rules_description')
          : t('pages.element_settings.map.styling_settings.color_rules_empty_description')}
      </p>

      {pinRules.map((pinRule, pinRuleIndex) => (
        <div
          key={`${pinRule.color}-${pinRule.field}-${pinRule.operator}-${pinRule.value}`}
          className="[&:not(:last-child)]:mb-2"
        >
          <PinRuleCard
            pinRule={pinRule}
            pinRuleIndex={pinRuleIndex}
            sourceObject={sourceObject}
            onFilterSave={onFilterSave}
            onFilterDelete={onFilterDelete}
          />
        </div>
      ))}

      {isAddingFilter && (
        <PinRuleForm
          pinRule={defaultPinRule}
          sourceObject={sourceObject}
          onSave={onFilterSave}
          onCancel={() => setIsAddingFilter(false)}
        />
      )}

      <div className="mt-4">
        <Button
          intent="secondary"
          onClick={() => setIsAddingFilter(true)}
          disabled={isAddingFilter}
        >
          <PlusIcon size={16} className="mr-2" />
          {t('pages.element_settings.map.styling_settings.add_color_rule')}
        </Button>
      </div>
    </div>
  );
}
